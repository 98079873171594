import React from 'react';
import {
  Button,
  Gutter,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalSection,
  Paragraph,
  ParagraphHeader,
} from '@axiom/ui';

export type ExperienceEditFormSkillsReviewModalType = {
  onClose: () => void;
  onSubmit: () => void;
};

export const ExperienceEditFormSkillsReviewModal = ({
  onClose,
  onSubmit,
}: ExperienceEditFormSkillsReviewModalType) => {
  return (
    <Modal name="EXPERIENCE_EDIT_FORM_SKILLS_REVIEW_MODAL" size="confirm">
      <ModalHeader name="REVIEW_MODAL_HEADER" onClose={() => onClose()}>
        Review skills
      </ModalHeader>
      <ModalSection>
        <ParagraphHeader>
          You have not reviewed all suggested skills.
        </ParagraphHeader>
        <Gutter bottom="16px" />
        <Paragraph>
          It’s okay if you want to go with the skills we suggested - but we just
          wanted to make sure you didn’t miss this step!
        </Paragraph>
      </ModalSection>
      <ModalFooter>
        <Button name="CANCEL" variation="outline" onClick={() => onClose()}>
          Go Back
        </Button>
        <Button name="SUBMIT" onClick={() => onSubmit()}>
          Apply Skills
        </Button>
      </ModalFooter>
    </Modal>
  );
};
