import { z } from 'zod';
import { CalendarEventSchema } from '@axiom/validation';

export const CalendarFormSchema = z
  .object({
    CALENDAR_COMPONENT: CalendarEventSchema.array(),
    TIMEZONE_DROPDOWN: z.string().uuid(),
  })
  .superRefine((values, ctx) => {
    if (values.CALENDAR_COMPONENT.some(event => !!event.error)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['CALENDAR_COMPONENT'],
        message: 'Please select at least one available time to continue.',
      });
    }
  });

export type CalendarFormType = z.infer<typeof CalendarFormSchema>;
