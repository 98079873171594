import React, { useMemo } from 'react';
import {
  Modal,
  ModalHeader,
  ModalSection,
  ModalFooter,
  Form,
  Button,
  Dropdown,
  Grid,
  GridRow,
  GridColumn,
  Gutter,
  useApi,
  DateUtil,
  FormLabel,
  AsyncConnector,
} from '@axiom/ui';
import {
  Candidate,
  Education,
  EducationEditSchema,
  LawSchoolSearch,
} from '@axiom/validation';

import { AreasOfStudyApi } from '../../api/areas-of-study-api';
import { CandidateLegacyApi } from '../../api/candidate-legacy-api';
import { CandidateDegreesApi } from '../../api/candidate-degrees-api';
import { CandidateProfileIssuesApi } from '../../api/candidate-profile-issues-api';
import { EducationApi } from '../../api/education-api';
import { EducationUtil } from '../../utils/education-util';
import { LawSchoolApi } from '../../api/law-schools-api';

import { EducationBannerPrompt } from './EducationBannerPrompt';

const formSchema = EducationEditSchema.pick({
  lawSchoolId: true,
  areaOfStudyId: true,
  yearAwarded: true,
  degree: true,
  candidateId: true,
});

export type EducationEditModalProps = {
  educations: Education[];
  candidateId: Candidate['id'];
  modifyEducation: Partial<Education>;
  onClose: () => void;
};

export const EducationEditModal = ({
  educations,
  candidateId,
  modifyEducation,
  onClose,
}: EducationEditModalProps) => {
  const [{ data: areasOfStudy }] = useApi(AreasOfStudyApi.readAreasOfStudy());
  const lawSchoolConnector = useMemo(
    () =>
      new AsyncConnector<LawSchoolSearch>({
        getData: args => {
          return LawSchoolApi.readLawSchools(args.search, args.page);
        },
        parseData: response => {
          return {
            currentPage: response.meta.currentPage,
            totalPages: response.meta.pageCount,
            resultCount: response.meta.resultCount,
            data: response.data,
          };
        },
      }),
    [modifyEducation.lawSchoolId]
  );

  return (
    <Form
      name="EDUCATION_EDIT_FORM"
      schema={formSchema}
      initialValues={{ ...modifyEducation, candidateId }}
      onSubmit={async changedValues => {
        await (modifyEducation.id
          ? EducationApi.updateEducation(modifyEducation.id, changedValues)
          : CandidateDegreesApi.createEducation(candidateId, {
              candidateId,
              ...changedValues,
            }));

        await CandidateLegacyApi.refreshCandidate(candidateId);
        await CandidateProfileIssuesApi.refreshCandidateProfileIssues(
          candidateId
        );
        return onClose();
      }}
    >
      {({ fireSubmit }) => {
        return (
          <Modal name="EDUCATION_EDIT_MODAL">
            <ModalHeader name="EDUCATION_EDIT_MODAL_HEADER" onClose={onClose}>
              Edit Education
            </ModalHeader>
            <ModalSection disableScrolling>
              {educations.length === 0 && (
                <Gutter bottom="16px">
                  <EducationBannerPrompt />
                </Gutter>
              )}
              <Grid>
                <GridRow>
                  <GridColumn>
                    <Dropdown
                      description="If your institution does not appear, reach out to your talent partner and they can make it available."
                      displayKey="name"
                      label="Institution"
                      name="lawSchoolId"
                      options={lawSchoolConnector}
                      valueKey="id"
                    />
                  </GridColumn>
                </GridRow>
                <GridRow>
                  <GridColumn largeScreenWidth={4} smallScreenWidth={12}>
                    <Gutter top="16px" />
                    <Gutter bottom="8px">
                      <FormLabel name="PENDO-CHOOSE-AREA-OF-STUDY">
                        Area of study
                      </FormLabel>
                    </Gutter>
                    <Dropdown
                      displayKey="label"
                      name="areaOfStudyId"
                      options={EducationUtil.getAreasOfStudyOptions(
                        areasOfStudy
                      )}
                      valueKey="value"
                    />
                  </GridColumn>
                  <GridColumn largeScreenWidth={4} smallScreenWidth={12}>
                    <Gutter top="16px" />
                    <Dropdown
                      displayKey="label"
                      label="Year"
                      name="yearAwarded"
                      options={DateUtil.getYearOptionsByRange(
                        new Date().getFullYear() + 10,
                        1950
                      )}
                      valueKey="value"
                    />
                  </GridColumn>
                  <GridColumn largeScreenWidth={4} smallScreenWidth={12}>
                    <Gutter top="16px" />
                    <Dropdown
                      displayKey="label"
                      label="Degree"
                      name="degree"
                      options={EducationUtil.getDegreeOptions(
                        educations,
                        modifyEducation
                      )}
                      valueKey="value"
                    />
                  </GridColumn>
                </GridRow>
              </Grid>
            </ModalSection>
            <ModalFooter>
              <Button variation="outline" onClick={onClose} name="CANCEL">
                Cancel
              </Button>
              <Button type="submit" name="SAVE" onClick={fireSubmit}>
                Save
              </Button>
            </ModalFooter>
          </Modal>
        );
      }}
    </Form>
  );
};
