import React, { useContext, useEffect, useState } from 'react';
import isEqual from 'lodash/isEqual';
import {
  Candidate,
  Experience,
  Taxonomy,
  TaxonomyFocusAreaEnum,
} from '@axiom/validation';
import {
  Button,
  Gutter,
  Layout,
  Paragraph,
  SectionHeader,
  Well,
} from '@axiom/ui';

import { ExperienceApi } from '../../api/experience-api';
import { CandidateExperienceApi } from '../../api/candidate-experience-api';

import {
  ExperienceEditSectionKeys,
  ExperienceEditUtil,
  minimumDescriptionLengthToRetrieveSuggestions,
} from './experience-edit-util';
import { ExperienceEditContext } from './ExperienceEditContext';
import { ExperienceEditSection } from './ExperienceEditSection';
import { ExperienceEditFormFocusAreasSort } from './ExperienceEditFormFocusAreasSort';
import { ExperienceEditFormFocusAreasSelect } from './ExperienceEditFormFocusAreasSelect';
import { FocusAreasSectionSchema } from './experience-edit-focus-areas-schema';

const { FOCUS_AREAS } = ExperienceEditSectionKeys;

export type ExperienceEditFormFocusAreasProps = {
  candidate: Candidate;
  expandable?: boolean;
  experience: Experience;
  taxonomy: Taxonomy;
};

export const ExperienceEditFormFocusAreas = ({
  candidate,
  expandable,
  experience,
  taxonomy,
}: ExperienceEditFormFocusAreasProps) => {
  const { accordionState, dirtyFormState, setDirtyFormState } = useContext(
    ExperienceEditContext
  );
  const initialFocusAreas =
    experience.experiencesFocusAreaOrderPreferences?.[0]?.focusAreas ?? [];
  const [focusAreasToSort, setFocusAreasToSort] =
    useState<TaxonomyFocusAreaEnum[]>(initialFocusAreas);
  const [isEditingFocusAreas, setIsEditingFocusAreas] = useState(false);

  useEffect(() => {
    setFocusAreasToSort(initialFocusAreas);
  }, [experience]);

  useEffect(() => {
    const isDirty = !isEqual(initialFocusAreas, focusAreasToSort);

    if (dirtyFormState[FOCUS_AREAS] !== isDirty) {
      setDirtyFormState((prevState: Record<string, boolean>) => ({
        ...prevState,
        [FOCUS_AREAS]: isDirty,
      }));
    }
  }, [focusAreasToSort, initialFocusAreas]);

  return (
    <ExperienceEditSection
      expandable={expandable}
      expanded={accordionState[FOCUS_AREAS]}
      isComplete={ExperienceEditUtil.isDataValid(
        FocusAreasSectionSchema,
        experience
      )}
      title="Focus Areas"
      name={FOCUS_AREAS}
    >
      {focusAreasToSort.length === 0 ? (
        <Well background="blue" name="EXPERIENCE_EDIT_FORM_SELECT_INTRO">
          <SectionHeader>Add focus areas</SectionHeader>
          <Gutter bottom="8px" />
          <Paragraph>
            Select the focus areas you worked in during this engagement. This
            helps Axiom really understand all the types of work you did at this
            client, and better match you in the future.
          </Paragraph>
          <Gutter bottom="16px" />
          <Layout position="right">
            <Button
              pattern="secondary"
              name="EXPERIENCE_EDIT_FORM_ADD_FOCUS_AREAS"
              onClick={async () => {
                if (
                  !experience.suggestedFocusAreasRetrievedAt &&
                  experience.description?.length >=
                    minimumDescriptionLengthToRetrieveSuggestions
                ) {
                  await ExperienceApi.createExperienceFocusAreaPredictions(
                    experience.id
                  );
                  await CandidateExperienceApi.refreshExperiences(candidate.id);
                }
                setIsEditingFocusAreas(true);
              }}
            >
              Add focus areas
            </Button>
          </Layout>
        </Well>
      ) : (
        <ExperienceEditFormFocusAreasSort
          candidateId={candidate.id}
          experience={experience}
          focusAreasToSort={focusAreasToSort}
          name="EXPERIENCE_EDIT_FORM_FOCUS_AREAS_SORT"
          onEditFocusAreasClicked={() => {
            setIsEditingFocusAreas(true);
          }}
          setFocusAreasToSort={setFocusAreasToSort}
          taxonomy={taxonomy}
        />
      )}

      {isEditingFocusAreas && (
        <ExperienceEditFormFocusAreasSelect
          candidate={candidate}
          experience={experience}
          focusAreas={focusAreasToSort}
          name="EXPERIENCE_EDIT_FORM_SELECT_FORM"
          onFocusAreasSelected={focusAreas => {
            setFocusAreasToSort(focusAreas);
            setIsEditingFocusAreas(false);
          }}
          taxonomy={taxonomy}
        />
      )}
    </ExperienceEditSection>
  );
};
