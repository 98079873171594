import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react';
import isEqual from 'lodash/isEqual';
import {
  Candidate,
  Experience,
  ExperienceTaxonomy,
  Taxonomy,
  TaxonomyFocusAreaEnum,
} from '@axiom/validation';
import {
  Badge,
  Button,
  CondensedLarge,
  FluidButtonLayout,
  Form,
  Gutter,
  IconButton,
  Layout,
  LayoutItem,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalSection,
  Paragraph,
  ParagraphHeader,
  SectionHeader,
  Sortable,
  Well,
  CandidateTaxonomyUtil,
} from '@axiom/ui';

import { ExperienceApi } from '../../api/experience-api';
import { CandidateExperienceApi } from '../../api/candidate-experience-api';
import { CandidateProfileIssuesApi } from '../../api/candidate-profile-issues-api';

import { ExperienceEditContext } from './ExperienceEditContext';
import {
  ExperienceEditSectionKeys,
  ExperienceEditUtil,
} from './experience-edit-util';
import { FocusAreasFormSchema } from './experience-edit-focus-areas-schema';

const { FOCUS_AREAS, SKILLS } = ExperienceEditSectionKeys;

export type ExperienceEditFormFocusAreasSortProps = {
  candidateId: Candidate['id'];
  experience: Experience;
  focusAreasToSort: TaxonomyFocusAreaEnum[];
  name: string;
  onEditFocusAreasClicked: () => void;
  setFocusAreasToSort: Dispatch<SetStateAction<string[]>>;
  taxonomy: Taxonomy;
};

export const ExperienceEditFormFocusAreasSort = ({
  candidateId,
  experience,
  focusAreasToSort,
  name,
  onEditFocusAreasClicked,
  setFocusAreasToSort,
  taxonomy,
}: ExperienceEditFormFocusAreasSortProps) => {
  const {
    accordionState,
    setAccordionState,
    setDirtyFormState,
    dirtyFormState,
  } = useContext(ExperienceEditContext);
  const [showReorderWarningModal, setShowReorderWarningModal] = useState(false);
  const legalSkillsTaxonomy = useMemo(
    () =>
      CandidateTaxonomyUtil.getLegalSkillsExperienceTaxonomy(
        experience.experienceTaxonomy ?? []
      ),
    [experience]
  );
  const initialValues = {
    focusAreas: focusAreasToSort,
  };
  const originalFocusAreaOrder =
    experience.experiencesFocusAreaOrderPreferences?.[0]?.focusAreas ?? [];

  return (
    <Form
      name={name}
      schema={FocusAreasFormSchema}
      initialValues={initialValues}
      onSubmit={async changedData => {
        if (
          !changedData.focusAreas &&
          initialValues.focusAreas.length > 1 &&
          !experience.experiencesFocusAreaOrderPreferences?.length &&
          !showReorderWarningModal
        ) {
          return setShowReorderWarningModal(true);
        }

        /* ******************************
        NOTE: There is a form schema issue occurring when the focusAreaOrder
        is updated while the SKILLS accordion is open. To get around this issue,
        we need to ensure the SKILLS accordion is closed first before making
        the update
        ****************************** */
        if (accordionState[SKILLS]) {
          setAccordionState({
            ...ExperienceEditUtil.setAllToFalse(accordionState),
            [SKILLS]: false,
          });
        }

        const { focusAreas } = {
          ...initialValues,
          ...changedData,
        };

        const focusAreasTaxonomy = focusAreas.map(focusArea => {
          return {
            experienceId: experience.id,
            practiceArea: taxonomy.focusAreas[focusArea].practiceArea,
            focusArea,
            skillGroup: null,
            legalSkills: null,
            dsSuggestion: false,
          } as ExperienceTaxonomy;
        });
        await ExperienceApi.updateCandidateExperienceTaxonomy(
          candidateId,
          experience.id,
          [
            ...focusAreasTaxonomy,
            ...legalSkillsTaxonomy.filter(({ focusArea }) =>
              focusAreas.includes(focusArea)
            ),
          ]
        );
        await Promise.all([
          CandidateExperienceApi.refreshExperiences(candidateId),
          CandidateProfileIssuesApi.refreshCandidateProfileIssues(candidateId),
        ]);
        setAccordionState({
          ...ExperienceEditUtil.setAllToFalse(accordionState),
          [SKILLS]: true,
        });

        return setShowReorderWarningModal(false);
      }}
    >
      {({ fireSubmit, values, setValues }) => {
        // https://stackoverflow.com/questions/61031464/setstate-called-in-render-prop-is-causing-a-react-warning
        setTimeout(() => {
          /* ******************************
          Note: Need to check against the source data since initialFocusAreas
          can be different from the source data. initialFocusAreas can be modified
          from ExperienceEditFormFocusAreaSelect without modification with the
          source data
          ****************************** */
          const isDirty = !isEqual(originalFocusAreaOrder, values.focusAreas);
          if (dirtyFormState[FOCUS_AREAS] !== isDirty) {
            setDirtyFormState((prevState: Record<string, boolean>) => ({
              ...prevState,
              [FOCUS_AREAS]: isDirty,
            }));
          }
        }, 0);

        return (
          <div data-test="EXPERIENCE_EDIT_FORM_FOCUS_AREAS_SORT_FORM_BODY">
            <Well background="blue">
              <SectionHeader name="EXPERIENCE_EDIT_FORM_FOCUS_AREAS_SORT_FORM_WELL_HEADER">
                Rank the time you spent in each area while at{' '}
                {ExperienceEditUtil.getExperienceClientDateRangeLabel(
                  experience
                )}
              </SectionHeader>
              <Gutter bottom="8px" />
              <Paragraph>
                Help Axiom and clients understand where you spent most of your
                time during this experience by arranging your focus areas from
                most to least time spent.
              </Paragraph>
            </Well>
            <Gutter bottom="24px" />
            <Badge background="peach">Most time spent</Badge>
            <Gutter bottom="8px" />
            <Sortable
              displayKey="label"
              name="focusAreas"
              options={focusAreasToSort.map(focusArea => ({
                label: (
                  <Layout
                    name="EXPERIENCE_EDIT_FORM_FOCUS_AREAS_SORT_ITEM"
                    position="middle"
                  >
                    <LayoutItem fluid>
                      <CondensedLarge name="EXPERIENCE_EDIT_FORM_FOCUS_AREAS_SORT_LABEL">
                        {taxonomy.focusAreas[focusArea].fullName}
                      </CondensedLarge>
                    </LayoutItem>
                    <LayoutItem>
                      <IconButton
                        name="EXPERIENCE_EDIT_FORM_FOCUS_AREAS_SORT_TRASHCAN"
                        icon="trash"
                        variation="minimal"
                        pattern="secondary"
                        onClick={() => {
                          const focusAreas = values.focusAreas.filter(
                            focus => focus !== focusArea
                          );

                          setValues({
                            ...values,
                            focusAreas,
                          });

                          // trigger empty state
                          if (focusAreas.length === 0) {
                            setFocusAreasToSort(focusAreas);
                          }
                        }}
                      />
                    </LayoutItem>
                  </Layout>
                ),
                value: focusArea,
              }))}
              valueKey="value"
            />
            <Gutter bottom="8px" />
            <Badge background="light orange">Least time spent</Badge>
            <Gutter bottom="24px" />
            <Button
              pattern="secondary"
              variation="minimal"
              icon="pencil"
              name="EXPERIENCE_EDIT_FORM_EDIT_FOCUS_AREAS"
              onClick={() => {
                setFocusAreasToSort(values.focusAreas);
                onEditFocusAreasClicked();
              }}
            >
              Edit focus areas
            </Button>
            <Gutter bottom="24px" />
            <FluidButtonLayout>
              <Button
                pattern="secondary"
                onClick={() => {
                  fireSubmit();
                }}
                name="EXPERIENCE_EDIT_FORM_FOCUS_AREAS_SAVE"
              >
                Save
              </Button>
            </FluidButtonLayout>
            {showReorderWarningModal && (
              <Modal
                size="confirm"
                name="EXPERIENCE_EDIT_FORM_SORT_REORDER_WARNING_MODAL"
              >
                <ModalHeader
                  name="EXPERIENCE_EDIT_FORM_SORT_REORDER_WARNING_MODAL_HEADER"
                  onClose={() => setShowReorderWarningModal(false)}
                >
                  Re-order focus areas?
                </ModalHeader>
                <ModalSection>
                  <ParagraphHeader>
                    You have not re-ordered any focus areas.
                  </ParagraphHeader>
                  <Gutter bottom="16px" />
                  <Paragraph>
                    It’s okay if the focus areas are already in the correct
                    order - but we just wanted to make sure you didn’t miss this
                    step!
                  </Paragraph>
                </ModalSection>
                <ModalFooter>
                  <Button
                    name="EXPERIENCE_EDIT_FORM_SORT_REORDER_WARNING_MODAL_CANCEL"
                    variation="outline"
                    onClick={() => setShowReorderWarningModal(false)}
                  >
                    Go back
                  </Button>
                  <Button
                    name="EXPERIENCE_EDIT_FORM_SORT_REORDER_WARNING_MODAL_SUBMIT"
                    onClick={() => fireSubmit()}
                  >
                    They look good
                  </Button>
                </ModalFooter>
              </Modal>
            )}
          </div>
        );
      }}
    </Form>
  );
};
