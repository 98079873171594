import React, { useContext, useState } from 'react';
import {
  Accordions,
  AccordionsItem,
  AccordionsItemHeader,
  AccordionsItemSection,
  Badge,
  CondensedHeader,
  Flashy,
  Gutter,
  Icon,
  Layout,
  LayoutItem,
  ToastUtil,
} from '@axiom/ui';

import { ExperienceEditContext } from './ExperienceEditContext';
import { ExperienceEditDataLossWarningModal } from './ExperienceEditDataLossWarningModal';
import { ExperienceEditSectionKeys } from './experience-edit-util';

export type ExperienceEditSectionProps = {
  children: React.ReactNode;
  expandable?: boolean;
  expanded?: boolean;
  isComplete: boolean;
  name: string;
  title: string;
};

export const ExperienceEditSection = ({
  children,
  expandable = false,
  expanded = false,
  isComplete,
  name,
  title,
}: ExperienceEditSectionProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { accordionState, setAccordionState, dirtyFormState } = useContext(
    ExperienceEditContext
  );

  const toggleAccordion = (value: boolean) => {
    setAccordionState({ ...accordionState, [name]: value });
  };

  const isSectionEdited = (key: string) =>
    dirtyFormState[key] && accordionState[key];

  const shouldModalOpen = (isAccordionExpanding: boolean) => {
    for (const key in ExperienceEditSectionKeys) {
      if (isSectionEdited(key) && isAccordionExpanding) {
        return true;
      }
    }

    return false;
  };

  return (
    <>
      <Accordions name={`${name}_EXPERIENCE_EDIT_SECTION`}>
        <AccordionsItem name={`${name}_accordion`} expanded={expanded}>
          <AccordionsItemHeader
            name={`${name}_ACCORDION_HEADER`}
            disabled={!expandable || isSectionEdited(name)}
            onToggle={value => {
              if (expandable) {
                if (!isSectionEdited(name)) {
                  toggleAccordion(value);

                  if (shouldModalOpen(value)) {
                    setIsModalOpen(true);
                  }
                }
              } else {
                ToastUtil.add({
                  dismissible: true,
                  name: 'OPEN_NOT_ALLOWED',
                  type: 'error',
                  children: (
                    <>
                      Please complete and save the previous section(s) before
                      moving on
                    </>
                  ),
                });
              }
            }}
          >
            <Layout position="space-between middle">
              <LayoutItem>
                <CondensedHeader name="TITLE">{title}</CondensedHeader>
              </LayoutItem>
              <LayoutItem position="center">
                <Gutter right="16px">
                  {isComplete ? (
                    <Flashy color="controlSuccessPrimary">
                      <Layout>
                        <Icon name="circle-check-filled" />
                      </Layout>
                    </Flashy>
                  ) : (
                    <Badge
                      background="gray"
                      name="EXPERIENCE_EDIT_SECTION_BADGE"
                    >
                      TO DO
                    </Badge>
                  )}
                </Gutter>
              </LayoutItem>
            </Layout>
          </AccordionsItemHeader>
          <AccordionsItemSection>
            <Gutter top="24px">{children}</Gutter>
          </AccordionsItemSection>
        </AccordionsItem>
      </Accordions>
      {isModalOpen && (
        <ExperienceEditDataLossWarningModal
          onClose={() => {
            setIsModalOpen(false);
            toggleAccordion(false);
          }}
          onContinue={() => {
            setIsModalOpen(false);
            toggleAccordion(true);
          }}
        />
      )}
    </>
  );
};
