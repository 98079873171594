import React from 'react';
import {
  Badge,
  Banner,
  Brief,
  CandidateTaxonomyUtil,
  CandidateExperienceTaxonomyDetails,
  CondensedHeader,
  CondensedLarge,
  ConfirmSubmitDialog,
  DateUtil,
  Flashy,
  Gutter,
  IconButton,
  Layout,
  LayoutItem,
  Paragraph,
  ParagraphHeader,
  ReadonlyTextarea,
} from '@axiom/ui';
import {
  Candidate,
  CandidateProfileIssue,
  Experience,
  Taxonomy,
} from '@axiom/validation';

import { ExperienceApi } from '../../api/experience-api';
import { CandidateExperienceApi } from '../../api/candidate-experience-api';
import { CandidateLegacyApi } from '../../api/candidate-legacy-api';
import { CandidateProfileIssuesApi } from '../../api/candidate-profile-issues-api';

import { ExperiencesBannerPrompt } from './ExperiencesBannerPrompt';

export const ExperiencesItemDisplay = ({
  candidateId,
  experience,
  taxonomy,
  showMergeTip,
  issues,
  onExperienceNav,
}: {
  candidateId: Candidate['id'];
  experience: Experience;
  taxonomy: Taxonomy;
  showMergeTip: boolean;
  issues: CandidateProfileIssue[];
  onExperienceNav: () => void;
}) => {
  const { industries } = taxonomy;
  const deleteExperience = async () => {
    await ExperienceApi.deleteExperience(experience.id);

    await CandidateLegacyApi.refreshCandidate(candidateId);
    await CandidateProfileIssuesApi.refreshCandidateProfileIssues(candidateId);

    return CandidateExperienceApi.refreshExperiences(candidateId);
  };

  return (
    <div data-test="EXPERIENCE_ITEM_DISPLAY">
      <Brief
        briefMaxHeight="248px"
        pattern="secondary"
        key={`${experience.suggestMergeDismissedAt}${experience.suggestAxiomEngageDismissedAt}`}
      >
        <Layout position="middle">
          <LayoutItem fluid>
            {!!experience.isAxiom && (
              <Gutter bottom="8px">
                <Badge background="orange" name="AXIOM_EXPERIENCE_BADGE">
                  Axiom
                </Badge>
              </Gutter>
            )}
            <ParagraphHeader name="CLIENT">{experience.client}</ParagraphHeader>
          </LayoutItem>
          <LayoutItem>
            <Layout horizontalGutter="8px" position="middle">
              {!experience.isAxiom && (
                <ConfirmSubmitDialog
                  name="CONFIRM_DELETE_EXPERIENCE_DIALOG"
                  yesButtonText="Delete"
                  size="tiny"
                  modalTitle="Delete This Experience"
                  modalBody="Deleting this experience removes it from your Axiom for Talent Profile and the bio that Axiom shares with prospective clients."
                  Trigger={
                    <IconButton
                      icon="trash"
                      pattern="secondary"
                      variation="minimal"
                      name="DELETE_EXPERIENCE_ICON"
                    />
                  }
                  onYes={deleteExperience}
                />
              )}
              <IconButton
                name="EDIT_EXPERIENCE_BUTTON"
                icon="pencil"
                pattern="secondary"
                variation="minimal"
                onClick={() => onExperienceNav()}
              />
            </Layout>
          </LayoutItem>
        </Layout>
        <Gutter bottom="8px" />
        {!experience.isAxiom && (
          <Paragraph name="EXTERNAL_OPPORTUNITY_NAME">
            {experience.externalOpportunityName || '--'}
          </Paragraph>
        )}
        <Paragraph name="DATE_RANGE">
          {DateUtil.displayDateRange(experience.startDate, experience.endDate)}
        </Paragraph>
        <Paragraph name="LOCATION">
          {experience.isAxiom
            ? experience.opportunity.locationCityStateCountry
            : experience.locationName}
        </Paragraph>
        <Gutter bottom="16px" />
        <ExperiencesBannerPrompt
          issues={issues}
          experience={experience}
          onOpenEdit={() => onExperienceNav()}
        />
        {!experience.isAxiom &&
          /axiom/i.test(
            `${experience.client} ${experience.externalOpportunityName}`
          ) &&
          !experience.suggestAxiomEngageDismissedAt && (
            <>
              <Banner name="CONVERT_EXPERIENCE_HINT">
                <Layout>
                  <LayoutItem>
                    <ParagraphHeader as="span">
                      <Flashy color="textSecondary">
                        Should this be an Axiom experience?
                      </Flashy>
                    </ParagraphHeader>{' '}
                    It looks like this might be an engagement you had with Axiom
                    in the past. Your Talent Success Partner can assist in
                    marking it as an Axiom experience if needed.
                  </LayoutItem>
                  <LayoutItem>
                    <IconButton
                      icon="x-mark"
                      name="CONVERT_EXPERIENCE_HINT_DISMISS"
                      onClick={async () => {
                        await ExperienceApi.updateExperience(experience.id, {
                          suggestAxiomEngageDismissedAt:
                            DateUtil.formatAsTimestamp(new Date()),
                        });
                        await CandidateExperienceApi.refreshExperiences(
                          candidateId
                        );
                      }}
                      pattern="secondary"
                      variation="minimal"
                    />
                  </LayoutItem>
                </Layout>
              </Banner>
              <Gutter bottom="16px" />
            </>
          )}
        {!!showMergeTip && (
          <>
            <Banner name="MERGE_EXPERIENCE_HINT">
              <Layout>
                <LayoutItem>
                  <ParagraphHeader as="span">
                    <Flashy color="textSecondary">
                      Did you do the same work at both of these engagements?
                    </Flashy>
                  </ParagraphHeader>{' '}
                  If so, you should consider merging them. Reach out to your
                  talent partner to let them know which experience should be
                  kept.
                </LayoutItem>
                <LayoutItem>
                  <IconButton
                    icon="x-mark"
                    name="MERGE_EXPERIENCE_HINT_DISMISS"
                    onClick={async () => {
                      await ExperienceApi.updateExperience(experience.id, {
                        suggestMergeDismissedAt: DateUtil.formatAsTimestamp(
                          new Date()
                        ),
                      });
                      await CandidateExperienceApi.refreshExperiences(
                        candidateId
                      );
                    }}
                    pattern="secondary"
                    variation="minimal"
                  />
                </LayoutItem>
              </Layout>
            </Banner>
            <Gutter bottom="16px" />
          </>
        )}
        <ReadonlyTextarea
          value={experience.description || '--'}
          name="DESCRIPTION"
        />
        {!!experience.industryValue && (
          <Gutter top="16px">
            <CondensedHeader>Industries</CondensedHeader>
            <Gutter bottom="8px" />
            <CondensedLarge name="INDUSTRY">
              {industries[experience.industryValue].displayValue}
            </CondensedLarge>
          </Gutter>
        )}
      </Brief>
      {!!CandidateTaxonomyUtil.showExperienceTaxonomy(experience) && (
        <Gutter vertical="16px">
          <CandidateExperienceTaxonomyDetails
            experience={experience}
            taxonomy={taxonomy}
          />
        </Gutter>
      )}
    </div>
  );
};
