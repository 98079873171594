import React from 'react';
import { Link, resolvePath } from 'react-router-dom';
import {
  Opportunity,
  CandidateOpportunitiesConst,
  PositionsConst,
  WorkFeedPositionCalculatedTalentTypes,
  CandidateOccupationTypes,
  CandidateOccupationType,
  PositionCalculatedTalentType,
} from '@axiom/const';
import {
  Candidate,
  CandidateWorkFeedItem,
  CandidateOpportunity,
} from '@axiom/validation';
import moment from 'moment';
import { CondensedLarge, ToastUtil, CondensedHeader } from '@axiom/ui';

import { EnvUtil } from './env-util';
import { LocationUtil } from './location-util';

const {
  CandidateStatuses: {
    Submitted,
    Completed,
    Engaged,
    Interviewing,
    RepBioShare,
    Selected,
    Suggested,
    Warmed,
    WarmedUnsure,
    WarmedYes,
    InterestedRejected,
    Cooled,
    Rejected,
    TalentOptOut,
    Interested,
    Removed,
    ShortList,
  },
} = CandidateOpportunitiesConst;

export const WORK_FEED_VIEWS = {
  all: 'all',
  saved: 'saved',
  interested: 'interested',
  spv: 'spv',
} as const;

export type WorkFeedViewsType =
  (typeof WORK_FEED_VIEWS)[keyof typeof WORK_FEED_VIEWS];

export const WorkFeedUtil = {
  getWorkFeedCandidateStatuses: (workfeedItem: CandidateWorkFeedItem) => {
    const { CLOSED_LOST, CLOSED_WON, CONTRACT } = Opportunity;
    const { candidateIsInterested, candidateStatus, opportunity } =
      workfeedItem;
    const interestedStateSuggestedStatus =
      candidateStatus === Suggested && candidateIsInterested;
    const interestedStateShortlistedStatus =
      candidateStatus === ShortList && candidateIsInterested;

    const submittedCTAStatuses = [
      Submitted,
      Completed,
      Engaged,
      Interviewing,
      RepBioShare,
      Selected,
      Warmed,
      WarmedUnsure,
      WarmedYes,
    ];

    const removeCTAStatuses = [
      InterestedRejected,
      Cooled,
      Rejected,
      TalentOptOut,
      Removed,
    ];

    const specialStatusesOverStage = [Selected, Engaged, Completed];

    return {
      isInterested:
        candidateStatus === Interested ||
        interestedStateSuggestedStatus ||
        interestedStateShortlistedStatus,
      isSubmitted: submittedCTAStatuses.includes(candidateStatus),
      isClosed:
        opportunity.stage === CLOSED_WON ||
        opportunity.stage === CLOSED_LOST ||
        removeCTAStatuses.includes(candidateStatus),
      isSelected: specialStatusesOverStage.includes(candidateStatus),
      isFulfillmentCompleted: opportunity.stage === CONTRACT,
    };
  },
  getView: (path: string) => {
    let view: WorkFeedViewsType = WORK_FEED_VIEWS.all;

    if (path.toLowerCase().includes(WORK_FEED_VIEWS.saved)) {
      view = WORK_FEED_VIEWS.saved;
    } else if (path.toLowerCase().includes(WORK_FEED_VIEWS.interested)) {
      view = WORK_FEED_VIEWS.interested;
    }

    return view;
  },
  isAllView: (currView: WorkFeedViewsType) => {
    return currView.toLowerCase().includes(WORK_FEED_VIEWS.all);
  },
  isSavedView: (currView: WorkFeedViewsType) => {
    return currView.toLowerCase().includes(WORK_FEED_VIEWS.saved);
  },
  isInterestedView: (currView: WorkFeedViewsType) => {
    return currView.toLowerCase().includes(WORK_FEED_VIEWS.interested);
  },
  isSinglePageView: (currView: WorkFeedViewsType) => {
    return currView.toLowerCase().includes(WORK_FEED_VIEWS.spv);
  },
  transformTalentType: (occupationType: Candidate['occupationType']) => {
    switch (occupationType) {
      case 'Lawyer':
      case 'Lawyer-Flex': {
        return PositionsConst.CalculatedTalentTypes.Lawyer;
      }
      case 'Legal Support':
      case 'Legal Support-Flex': {
        return PositionsConst.CalculatedTalentTypes.NonLawyer;
      }
      default: {
        return null;
      }
    }
  },
  formatPhaseForDisplay: (
    phase: CandidateWorkFeedItem['phase']
  ): CandidateWorkFeedItem['phase'] | 'Submitting' => {
    switch (phase) {
      case 'Submitted': {
        return 'Submitting';
      }
      default: {
        return phase;
      }
    }
  },
  getQualificationWarnings: (
    candidate: Candidate,
    workFeedItem: CandidateWorkFeedItem,
    candidateOpportunities: CandidateOpportunity[]
  ) => {
    const { position, opportunity } = workFeedItem;
    const warnings = [];

    if (
      opportunity.countryCode &&
      candidate.addressCountryCode &&
      LocationUtil.qualifyCountryCode(candidate.addressCountryCode) !==
        LocationUtil.qualifyCountryCode(opportunity.countryCode)
    ) {
      warnings.push('Location or time zone');
    }

    if (
      candidate.practiceArea &&
      opportunity.practiceAreaId &&
      candidate.practiceArea.parentId !== opportunity.practiceAreaId
    ) {
      warnings.push('Practice area');
    }

    if (opportunity.languages?.length && candidate.languages?.length) {
      const candidateLanguageSet = new Set(
        candidate.languages?.map(candidateLanguage => candidateLanguage.name)
      );
      const languagesIntersections = opportunity.languages.filter(
        opportunityLanguage =>
          candidateLanguageSet.has(opportunityLanguage?.language?.name)
      );
      if (languagesIntersections.length === 0) {
        warnings.push('Language');
      }
    }

    if (candidate.occupationType && position.calculatedTalentType) {
      const occupationGroups: {
        [key in PositionCalculatedTalentType]: CandidateOccupationType[];
      } = {
        [WorkFeedPositionCalculatedTalentTypes.Lawyer]: [
          CandidateOccupationTypes.Lawyer,
          CandidateOccupationTypes.LawyerFlex,
        ],
        [WorkFeedPositionCalculatedTalentTypes.NonLawyer]: [
          CandidateOccupationTypes.LegalSupport,
          CandidateOccupationTypes.LegalSupportFlex,
        ],
      };
      if (
        !occupationGroups[position.calculatedTalentType].includes(
          candidate.occupationType
        )
      ) {
        warnings.push('Legal role');
      }
    }

    if (candidate.weeklyAvailability !== null && position.billingHoursPerWeek) {
      const isCandidateRollOffImminent = () => {
        if (!candidate.soonestEngagementEndDate) return false;

        const daysUntilRollOff = moment(
          candidate.soonestEngagementEndDate
        ).diff(moment(new Date()), 'days');

        return daysUntilRollOff >= 0 && daysUntilRollOff <= 30;
      };

      const getPositionByEndDate = (
        opportunities: CandidateOpportunity[],
        endDate: string | null
      ) => {
        if (!opportunities?.length) return null;

        for (const candidateOpportunity of opportunities) {
          if (
            candidateOpportunity.candidateStatus === Engaged &&
            candidateOpportunity?.position?.endDate === endDate
          ) {
            return candidateOpportunity.position;
          }
        }
        return null;
      };

      const shouldWarnWeeklyAvailability = () => {
        const rollOffPosition = getPositionByEndDate(
          candidateOpportunities,
          candidate.soonestEngagementEndDate
        );

        if (isCandidateRollOffImminent() && rollOffPosition) {
          return (
            (candidate.weeklyAvailability ?? 0) +
              (rollOffPosition.billingHoursPerWeek ?? 0) <
            (position.billingHoursPerWeek ?? 0)
          );
        }
        return (
          (candidate.weeklyAvailability ?? 0) <
          (position.billingHoursPerWeek ?? 0)
        );
      };

      if (shouldWarnWeeklyAvailability()) {
        warnings.push('Available weekly hours');
      }
    }

    return warnings;
  },
  addInterestedToasts: (interestedCount: number, targetLink: string) => {
    if (interestedCount === EnvUtil.workFeedMaxInterestedLimit - 1) {
      ToastUtil.add({
        children: (
          <div>
            <CondensedHeader>Limit Reached</CondensedHeader>
            <CondensedLarge>
              Axiom can consider you for up to{' '}
              {EnvUtil.workFeedMaxInterestedLimit} opportunities at a time.
            </CondensedLarge>
          </div>
        ),
        type: 'error',
        dismissible: true,
        name: 'LIMIT_REACHED',
      });
    }

    ToastUtil.add({
      children: (
        <div>
          <CondensedHeader>Interest Expressed</CondensedHeader>
          <CondensedLarge>
            This opportunity has been moved to your{' '}
            <Link
              data-test="INTERESTED_TAB_LINK"
              onClick={() => {
                ToastUtil.removeAll();
              }}
              to={resolvePath(targetLink, window.location.pathname)}
            >
              Interested tab.
            </Link>
          </CondensedLarge>
        </div>
      ),
      name: 'EXPRESS_INTEREST_SUCCESS',
    });
  },
  addSavedToasts: (targetLink: string) => {
    ToastUtil.add({
      children: (
        <div>
          <CondensedHeader>Opportunity Saved</CondensedHeader>
          <CondensedLarge>
            This opportunity has been moved to your{' '}
            <Link
              data-test="SAVED_TAB_LINK"
              onClick={() => {
                ToastUtil.removeAll();
              }}
              to={resolvePath(targetLink, window.location.pathname)}
            >
              Saved tab
            </Link>
            . We will alert you when this opportunity is ready to accept
            expressions of interest.
          </CondensedLarge>
        </div>
      ),
      name: 'OPPORTUNITY_SAVED_TOAST',
    });
  },
  addErrorToast: (email?: string | null) => {
    ToastUtil.add({
      children: (
        <div>
          <CondensedHeader>We need more information</CondensedHeader>
          <CondensedLarge>
            To complete your submission, contact your{' '}
            <a
              data-test="TALENT_SUCCESS_LINK"
              href={`mailto:${email || 'answers@axiomlaw.com'}`}
            >
              Talent Success Partner
            </a>
          </CondensedLarge>
        </div>
      ),
      name: 'EXPRESS_INTEREST_ERROR',
      type: 'error',
      dismissible: true,
    });
  },
  isUnavailableToCandidate: (workFeedItem: CandidateWorkFeedItem) => {
    const { isSubmitted, isSelected } =
      WorkFeedUtil.getWorkFeedCandidateStatuses(workFeedItem);
    return (
      workFeedItem.isNotAvailableOnCandidateFeed && !isSubmitted && !isSelected
    );
  },
};
