import React from 'react';
import { ProfileCompletionConst } from '@axiom/const';

import {
  BannerPromptProps,
  ProfileCompletionBanner,
} from '../ProfileCompletionBanner/ProfileCompletionBanner';

export const EducationBannerPrompt = ({
  onOpenEdit,
}: {
  onOpenEdit?: BannerPromptProps['onOpenEdit'];
}) => {
  return (
    <ProfileCompletionBanner
      items={[
        {
          title:
            ProfileCompletionConst.ProfileCompletionEducationBanner
              .missingTitle,
          description:
            ProfileCompletionConst.ProfileCompletionEducationBanner
              .missingContent,
          estimatedTime: '5 min',
        },
      ]}
      onOpenEdit={onOpenEdit}
      name="EDUCATION_BANNER_PROMPT"
    />
  );
};
