import React, { useContext } from 'react';
import {
  Candidate,
  CandidateProfileIssues,
  Experience,
  ExperienceEditSchema,
} from '@axiom/validation';
import { ProfileCompletionConst } from '@axiom/const';
import { Button, FluidButtonLayout, Form, Gutter, Textarea } from '@axiom/ui';
import { CandidateProfileIssuesUtils } from '@axiom/utils';

import { ExperienceApi } from '../../api/experience-api';
import { CandidateExperienceApi } from '../../api/candidate-experience-api';
import { CandidateProfileIssuesApi } from '../../api/candidate-profile-issues-api';

import {
  ExperienceEditSectionKeys,
  ExperienceEditUtil,
} from './experience-edit-util';
import { ExperienceEditContext } from './ExperienceEditContext';
import { ExperienceEditSection } from './ExperienceEditSection';
import { ExperienceEditIssue } from './ExperienceEditIssue';
import { WorkDetailsFormSchema } from './experience-edit-work-details-schema';

const { WORK_DETAILS, FOCUS_AREAS } = ExperienceEditSectionKeys;

export type ExperienceEditFormWorkDetailsProps = {
  candidateId: Candidate['id'];
  expandable?: boolean;
  experience: Experience;
  issues: CandidateProfileIssues['issues'];
};

export const ExperienceEditFormWorkDetails = ({
  candidateId,
  expandable,
  experience,
  issues,
}: ExperienceEditFormWorkDetailsProps) => {
  const {
    dirtyFormState,
    setDirtyFormState,
    accordionState,
    setAccordionState,
  } = useContext(ExperienceEditContext);
  const descriptionGuidanceIssue =
    CandidateProfileIssuesUtils.getExperienceDescriptionGuidanceIssue(
      issues,
      experience.id
    );

  return (
    <Form
      name="EXPERIENCE_EDIT_FORM_WORK_DETAILS"
      schema={WorkDetailsFormSchema}
      initialValues={{
        description: experience.description,
      }}
      onSubmit={async formData => {
        const submitData = {
          candidateId,
          ...formData,
        };
        await ExperienceApi.updateExperience(
          experience.id,
          ExperienceEditSchema.partial().parse(submitData)
        );
        await Promise.all([
          CandidateExperienceApi.refreshExperiences(candidateId),
          CandidateProfileIssuesApi.refreshCandidateProfileIssues(candidateId),
        ]);
        setAccordionState({
          ...ExperienceEditUtil.setAllToFalse(accordionState),
          [FOCUS_AREAS]: true,
        });
      }}
    >
      {({ dirty, fireSubmit }) => {
        // https://stackoverflow.com/questions/61031464/setstate-called-in-render-prop-is-causing-a-react-warning
        setTimeout(() => {
          if (dirtyFormState[WORK_DETAILS] !== dirty) {
            setDirtyFormState((prevState: Record<string, boolean>) => ({
              ...prevState,
              [WORK_DETAILS]: dirty,
            }));
          }
        }, 0);

        return (
          <ExperienceEditSection
            expandable={expandable}
            expanded={accordionState[WORK_DETAILS]}
            isComplete={ExperienceEditUtil.isWorkDetailsComplete(
              experience,
              issues
            )}
            title="Work Details"
            name={WORK_DETAILS}
          >
            <div data-test="EXPERIENCE_EDIT_FORM_WORK_DETAILS_BODY">
              {CandidateProfileIssuesUtils.experienceHasTooLongDescription(
                experience
              ) && (
                <Gutter bottom="16px">
                  <ExperienceEditIssue
                    title={
                      ProfileCompletionConst
                        .ProfileCompletionExperienceDescriptionBanner
                        .tooLongTitle
                    }
                    description={
                      ProfileCompletionConst
                        .ProfileCompletionExperienceDescriptionBanner
                        .tooLongContent
                    }
                    estimatedTime="10 min"
                    name="EXPERIENCE_EDIT_SHORTEN_DESCRIPTION"
                  />
                </Gutter>
              )}
              {descriptionGuidanceIssue && (
                <Gutter bottom="16px">
                  <ExperienceEditIssue
                    title={
                      ProfileCompletionConst
                        .ExperienceDescriptionBannerConfigByCandidateProfileIssueContext[
                        descriptionGuidanceIssue.context
                      ].title
                    }
                    description={
                      ProfileCompletionConst
                        .ExperienceDescriptionBannerConfigByCandidateProfileIssueContext[
                        descriptionGuidanceIssue.context
                      ].description
                    }
                    estimatedTime={
                      ProfileCompletionConst
                        .ExperienceDescriptionBannerConfigByCandidateProfileIssueContext[
                        descriptionGuidanceIssue.context
                      ].estimatedTime
                    }
                    name="EXPERIENCE_EDIT_DESCRIPTION_GUIDANCE"
                  />
                </Gutter>
              )}
              <Textarea
                name="description"
                label="Description of work"
                full
                enableSuggestionsFor="portal-ExperienceItemNonAxiomEdit-description"
              />
              <Gutter bottom="16px" />
              <FluidButtonLayout>
                <Button
                  pattern="secondary"
                  onClick={fireSubmit}
                  name="EXPERIENCE_EDIT_FORM_WORK_DETAILS_SAVE"
                >
                  Save
                </Button>
              </FluidButtonLayout>
            </div>
          </ExperienceEditSection>
        );
      }}
    </Form>
  );
};
