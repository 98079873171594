import { ZodSchema } from 'zod';
import {
  CandidateProfileIssues,
  Experience,
  ExperienceTaxonomy,
  Taxonomy,
  TaxonomyLegalSkill,
  TaxonomyFocusArea,
  TaxonomySkillGroup,
  TaxonomyFocusAreaEnum,
  TaxonomyLegalSkillEnum,
} from '@axiom/validation';
import { DateUtil, TaxonomyUtil } from '@axiom/ui';
import { CandidateProfileIssuesUtils } from '@axiom/utils';

import { WorkDetailsFormSchema } from './experience-edit-work-details-schema';

type SectionKey = {
  key: string;
};

export const ExperienceEditSectionKeys = {
  COMPANY_DETAILS: 'COMPANY_DETAILS',
  WORK_DETAILS: 'WORK_DETAILS',
  FOCUS_AREAS: 'FOCUS_AREAS',
  SKILLS: 'SKILLS',
} as const;

export type SkillSectionType = {
  focusArea: SectionKey & TaxonomyFocusArea;
  skillGroups: {
    skillGroup: SectionKey & TaxonomySkillGroup;
    legalSkills: (SectionKey & TaxonomyLegalSkill)[];
  }[];
};

export const minimumDescriptionLengthToRetrieveSuggestions = 100;

export const ExperienceEditUtil = {
  setAllToFalse: (state: Record<string, boolean>) => {
    return Object.keys(state).reduce(
      (acc: Record<string, boolean>, key: string) => {
        acc[key] = false;
        return acc;
      },
      {}
    );
  },

  isDataValid: (schema: ZodSchema, data: Record<string, unknown>) => {
    return schema.safeParse(data).success;
  },

  getLegalSkillsByFocusArea: (experienceTaxonomy: ExperienceTaxonomy[]) => {
    return experienceTaxonomy.reduce(
      (acc, curr) => {
        const legalSkills = curr.legalSkills ?? [];
        if (acc[curr.focusArea]) {
          acc[curr.focusArea].push(...legalSkills);
        } else {
          acc[curr.focusArea] = [...legalSkills];
        }
        return acc;
      },
      {} as Record<TaxonomyFocusAreaEnum, TaxonomyLegalSkillEnum[]>
    );
  },

  isWorkDetailsComplete: (
    experience: Experience,
    issues: CandidateProfileIssues['issues']
  ) => {
    return (
      ExperienceEditUtil.isDataValid(WorkDetailsFormSchema, experience) &&
      !CandidateProfileIssuesUtils.getExperienceDescriptionGuidanceIssue(
        issues,
        experience.id
      )
    );
  },

  isSkillsComplete: (experienceTaxonomy: ExperienceTaxonomy[] = []) => {
    const hasDsSuggestions = !!experienceTaxonomy.some(et => et.dsSuggestion);
    const legalSkillsByFocusArea =
      ExperienceEditUtil.getLegalSkillsByFocusArea(experienceTaxonomy);
    const hasEmptyFocusAreaLegalSkills = Object.keys(legalSkillsByFocusArea)
      .map(key => legalSkillsByFocusArea[key])
      .some(arr => arr.length === 0);

    return (
      !hasDsSuggestions &&
      !hasEmptyFocusAreaLegalSkills &&
      experienceTaxonomy.length > 0
    );
  },

  getSkillsSectionConfiguration: (
    focusAreaOrder: TaxonomyFocusAreaEnum[],
    taxonomy: Taxonomy,
    isCandidateAlp: boolean
  ): SkillSectionType[] => {
    const configuration = focusAreaOrder.map(focusArea => {
      return {
        focusArea: {
          key: focusArea,
          ...taxonomy.focusAreas[focusArea],
        },
        skillGroups: TaxonomyUtil.getFocusAreaSkillGroups(
          focusArea,
          taxonomy.skillGroups
        )
          .sort((a, b) => a.key.localeCompare(b.key))
          .map(skillGroup => ({
            skillGroup,
            legalSkills: TaxonomyUtil.getLegalSkillsBySkillGroup(
              skillGroup.key,
              taxonomy.legalSkills
            ).sort((a, b) => a.key.localeCompare(b.key)),
          })),
      };
    });

    if (isCandidateAlp) {
      return configuration
        .map(fa => ({
          ...fa,
          skillGroups: fa.skillGroups
            .map(sg => ({
              ...sg,
              legalSkills: sg.legalSkills.filter(
                legalSkill => legalSkill.alpEligible
              ),
            }))
            .filter(({ skillGroup }) => skillGroup.alpEligible),
        }))
        .filter(({ focusArea }) => focusArea.alpEligible);
    }

    return configuration;
  },
  getExperienceClientDateRangeLabel: (
    experience: Experience
  ): string | null => {
    if (!experience) {
      return '';
    }

    const dateRange =
      experience.startDate &&
      `(${DateUtil.displayDateRange(experience.startDate, experience.endDate)})`;

    return [experience.client, dateRange].filter(Boolean).join(' ');
  },
};
