import qs from 'qs';
import isUuid from 'is-uuid';
import {
  PositionsConst,
  TimeCommitment as TimeCommitmentConst,
  EngagementLengthConst,
  CountryCodesConst,
  WorkFeedConst,
} from '@axiom/const';
import {
  Candidate,
  CandidateWorkFeedFormFilter,
  PracticeArea,
} from '@axiom/validation';

import { getTreeTrunk } from './dropdown-tree-util';
import { WorkFeedUtil } from './WorkFeedUtil';
import { localStorageUtil } from './local-storage-util';

type OptionType = {
  label: string;
  value: string;
  type: string;
};

const { CountryCodes, CountryCodesAbbreviations } = CountryCodesConst;
const { EngagementLengths } = EngagementLengthConst;
const { Phases } = WorkFeedConst;
const { HoursPerWeek } = TimeCommitmentConst;
const { CalculatedTalentTypes, Worksites } = PositionsConst;

const opportunityStageOptions = [
  Phases.New,
  Phases.Submitted,
  Phases.Interviewing,
].map(phase => ({
  value: phase,
  label: WorkFeedUtil.formatPhaseForDisplay(phase),
}));

const weeklyCommitmentOptions = [
  HoursPerWeek.FortyPlus,
  HoursPerWeek.ThirtyOneToForty,
  HoursPerWeek.TwentyOneToThirty,
  HoursPerWeek.ElevenToTwenty,
  HoursPerWeek.OneToTen,
].map(hrsPerWeek => ({
  value: hrsPerWeek,
  label: `${hrsPerWeek} hrs`,
}));

const countryOptions = [
  { label: CountryCodes.US, value: CountryCodesAbbreviations.US },
  { label: CountryCodes.UK, value: CountryCodesAbbreviations.UK },
  { label: CountryCodes.CA, value: CountryCodesAbbreviations.CA },
  { label: CountryCodes.SG, value: CountryCodesAbbreviations.SG },
  { label: CountryCodes.HK, value: CountryCodesAbbreviations.HK },
  { label: CountryCodes.DE, value: CountryCodesAbbreviations.DE },
  { label: CountryCodes.CH, value: CountryCodesAbbreviations.CH },
];

const worksiteOptions = [
  { value: Worksites.Remote, label: 'Remote' },
  { value: Worksites.OnsiteRemote, label: 'Hybrid' },
  { value: Worksites.Onsite, label: 'Onsite' },
];

const engagementLengthOptions = [
  EngagementLengths.ZeroToThree,
  EngagementLengths.FourToSix,
  EngagementLengths.SevenToNine,
  EngagementLengths.TenToTwelve,
  EngagementLengths.TwelvePlus,
].map(el => ({
  value: el,
  label: `${el} months`,
}));

const legalRoleOptions = [
  CalculatedTalentTypes.Lawyer,
  CalculatedTalentTypes.NonLawyer,
].map(talentType => ({
  value: talentType,
  label: talentType,
}));

const formatOptions = (response: PracticeArea[]) => {
  const filtered = response.filter(data => data.type);

  return {
    options: getTreeTrunk(filtered, true) as OptionType[],
    raw: filtered.reduce(
      (accum: Record<PracticeArea['id'], PracticeArea>, current) => {
        accum[current.id] = current;
        return accum;
      },
      {}
    ),
  };
};

const getInitialFilters = (
  candidate: Candidate,
  practiceAreas: PracticeArea[]
): Required<
  Pick<
    CandidateWorkFeedFormFilter,
    'countries' | 'practiceAreaId' | 'talentTypes'
  >
> => {
  const practiceArea = practiceAreas.find(
    pa => pa.id === candidate.practiceAreaId
  );
  const practiceAreaId = practiceArea?.parentId || practiceArea?.id || null;
  const talentType = WorkFeedUtil.transformTalentType(candidate.occupationType);

  return {
    countries:
      candidate.addressCountryCode &&
      countryOptions.some(o => o.value === candidate.addressCountryCode)
        ? [candidate.addressCountryCode]
        : [],
    practiceAreaId:
      practiceAreaId &&
      formatOptions(practiceAreas).options.some(o => o.value === practiceAreaId)
        ? [practiceAreaId]
        : [],
    talentTypes: talentType ? [talentType] : [],
  };
};

// Clears out any filters that were transitioned from UUIDs to enums
const washFilters = (filters: CandidateWorkFeedFormFilter) => {
  const transitionedFilters: Array<keyof CandidateWorkFeedFormFilter> = [
    'industries',
  ];

  transitionedFilters.forEach(fil => {
    const filter = filters[fil];
    if (filter?.some(opt => isUuid.anyNonNil(opt))) {
      delete filters[fil];
    }
  });

  return filters;
};

// qs by default converts arrays with > 20 items to an object with the index as the key
// the limit is increased here since industries can have up to 36 selections
const getFilters = (
  searchParams: URLSearchParams,
  candidate: Candidate,
  practiceAreas: PracticeArea[]
): CandidateWorkFeedFormFilter => {
  const { countries, practiceAreaId, talentTypes } = getInitialFilters(
    candidate,
    practiceAreas
  );

  const paramFilters = searchParams.get('filters');

  return paramFilters
    ? washFilters(qs.parse(paramFilters, { arrayLimit: 40 }))
    : localStorageUtil
        .workFeedFilters(candidate.id, practiceAreaId, countries, talentTypes)
        .get();
};

const applyFilters = (
  setSearchParams: (arg: { filters: string }) => void,
  candidate: Candidate,
  practiceAreas: PracticeArea[],
  filters?: Record<string, string | string[] | null> | null
) => {
  setSearchParams({
    filters: filters ? qs.stringify(filters) : '',
  });
  const defaultFilters = getInitialFilters(candidate, practiceAreas);
  localStorageUtil.workFeedFilters(candidate.id).set({
    ...filters,
    countries: filters?.countries?.length
      ? filters.countries
      : defaultFilters.countries,
    practiceAreaId: filters?.practiceAreaId?.length
      ? filters.practiceAreaId
      : defaultFilters.practiceAreaId,
    talentTypes: filters?.talentTypes?.length
      ? filters.talentTypes
      : defaultFilters.talentTypes,
  });
};

export const WorkFeedFilterUtil = {
  getFilters,
  applyFilters,
  washFilters,
  opportunityStageOptions,
  weeklyCommitmentOptions,
  countryOptions,
  worksiteOptions,
  engagementLengthOptions,
  legalRoleOptions,
};
