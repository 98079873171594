import { createContext } from 'react';

export type ProgressGroupContextType = {
  showLabel: boolean;
  graduations: number;
};
export const ProgressGroupContext = createContext<ProgressGroupContextType>({
  showLabel: true,
  graduations: 0,
});
