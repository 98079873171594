import React from 'react';
import {
  Banner,
  Button,
  CondensedSmall,
  Flashy,
  Grid,
  GridColumn,
  GridRow,
  Gutter,
  Layout,
  LayoutItem,
  Paragraph,
  SectionHeader,
} from '@axiom/ui';

interface BannerPromptItem {
  title: string;
  description: string;
  estimatedTime: string;
  name?: string;
}

export interface BannerPromptProps {
  items: BannerPromptItem[];
  onOpenEdit?: (() => void) | null;
  name?: string | null;
}

export const ProfileCompletionBanner = ({
  items,
  onOpenEdit = null,
  name = null,
}: BannerPromptProps) => {
  return (
    <Banner name={name ?? 'BANNER_PROMPT'} type="info" impact="high">
      <Grid>
        <GridRow>
          <GridColumn
            smallScreenWidth={12}
            largeScreenWidth={onOpenEdit ? 9 : 12}
          >
            <Layout verticalGutter="16px" direction="vertical">
              {items.map(
                ({ title, estimatedTime, description, name: itemName }) => (
                  <LayoutItem
                    key={itemName ?? title}
                    name={itemName ?? 'BANNER_PROMPT_ITEM'}
                  >
                    <Layout horizontalGutter="8px" position="middle" wrap>
                      <LayoutItem>
                        <SectionHeader name="TITLE">{title}</SectionHeader>
                      </LayoutItem>
                      <LayoutItem>
                        <CondensedSmall>
                          <Flashy color="textSubtle">|</Flashy>
                        </CondensedSmall>
                      </LayoutItem>
                      <LayoutItem>
                        <CondensedSmall>
                          <Flashy color="textSubtle" name="ESTIMATED_TIME">
                            {estimatedTime}
                          </Flashy>
                        </CondensedSmall>
                      </LayoutItem>
                    </Layout>
                    <Gutter bottom="8px" />
                    <Paragraph name="DESCRIPTION">{description}</Paragraph>
                  </LayoutItem>
                )
              )}
            </Layout>
          </GridColumn>
          {!!onOpenEdit && (
            <GridColumn smallScreenWidth={12} largeScreenWidth={3}>
              <Gutter top="16px" only="smallScreen" />
              <Layout position="right">
                <LayoutItem>
                  <Button
                    name="BANNER_PROMPT_BUTTON"
                    onClick={() => onOpenEdit()}
                  >
                    Edit Now
                  </Button>
                </LayoutItem>
              </Layout>
            </GridColumn>
          )}
        </GridRow>
      </Grid>
    </Banner>
  );
};
