import { createContext } from 'react';

export type DrawerContextType = {
  isExpanded: boolean;
  setIsExpanded: (isExpanded: boolean) => void;
};

export const DrawerContext = createContext<DrawerContextType>({
  isExpanded: false,
  setIsExpanded: () => {},
});
