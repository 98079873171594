import React from 'react';
import { ProfileCompletionConst } from '@axiom/const';
import { CandidateProfileIssue, Experience } from '@axiom/validation';
import { CandidateProfileIssuesUtils } from '@axiom/utils';
import { Gutter } from '@axiom/ui';

import { ProfileCompletionBanner } from '../ProfileCompletionBanner/ProfileCompletionBanner';

const {
  ProfileCompletionExperienceDescriptionBanner,
  ProfileCompletionExperienceIndustryBanner,
  ProfileCompletionExperienceTaxonomyBanner,
} = ProfileCompletionConst;

export type ExperienceBannerPromptProps = {
  issues: CandidateProfileIssue[];
  experience: Experience;
  onOpenEdit?: () => void;
};

export const ExperiencesBannerPrompt = ({
  issues,
  experience,
  onOpenEdit = null,
}: ExperienceBannerPromptProps) => {
  const descriptionGuidanceIssue =
    CandidateProfileIssuesUtils.getExperienceDescriptionGuidanceIssue(
      issues,
      experience.id
    );
  const items = [
    CandidateProfileIssuesUtils.hasExperienceWithMissingIndustryIssue(
      issues,
      experience.id
    ) && {
      title: ProfileCompletionExperienceIndustryBanner.missingTitle,
      description: ProfileCompletionExperienceIndustryBanner.missingContent,
      estimatedTime: '1 min',
      name: 'ADD_INDUSTRY_HINT',
    },
    CandidateProfileIssuesUtils.experienceHasTooLongDescription(experience) && {
      title: ProfileCompletionExperienceDescriptionBanner.tooLongTitle,
      description: ProfileCompletionExperienceDescriptionBanner.tooLongContent,
      estimatedTime: '10 min',
      name: 'SHORTEN_DESCRIPTION_HINT',
    },
    descriptionGuidanceIssue && {
      ...ProfileCompletionConst
        .ExperienceDescriptionBannerConfigByCandidateProfileIssueContext[
        descriptionGuidanceIssue.context
      ],
      name: 'EXPERIENCE_DESCRIPTION_GUIDANCE',
    },
    CandidateProfileIssuesUtils.hasExperienceWithMissingTaxonomyIssue(
      issues,
      experience.id
    ) && {
      title: ProfileCompletionExperienceTaxonomyBanner.missingTitle,
      description: ProfileCompletionExperienceTaxonomyBanner.missingContent,
      estimatedTime: '10 min',
      name: 'ADD_TAXONOMY_HINT',
    },
  ].filter(Boolean);

  return items.length > 0 ? (
    <Gutter bottom="16px">
      <ProfileCompletionBanner
        items={items}
        onOpenEdit={onOpenEdit}
        name="EXPERIENCE_BANNER_PROMPT"
      />
    </Gutter>
  ) : null;
};
