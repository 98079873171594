import { Api } from '@axiom/ui';
import {
  Candidate,
  Experience,
  ExperienceEdit,
  ExperienceTaxonomy,
  ExperienceTaxonomyFocusArea,
  ExperienceTaxonomyLegalSkill,
} from '@axiom/validation';

import { EnvUtil } from '../utils/env-util';

class ExperienceApiClass extends Api {
  constructor() {
    super({
      domain: EnvUtil.apiPortalUrl,
    });
  }

  createExperience(candidateId: Candidate['id'], experience: ExperienceEdit) {
    return super.write<{ data: Experience }>({
      endpoint: `/candidates/${candidateId}/experiences`,
      body: experience,
      method: 'POST',
    });
  }

  updateExperience(id: Experience['id'], experience: ExperienceEdit) {
    return super.write<{ data: Experience }>({
      endpoint: `/experiences/${id}`,
      body: experience,
      method: 'PATCH',
    });
  }

  deleteExperience(id: Experience['id']) {
    return super.write<{ data: Experience }>({
      endpoint: `/experiences/${id}`,
      method: 'DELETE',
    });
  }

  updateCandidateExperienceTaxonomy(
    candidateId: Candidate['id'],
    id: Experience['id'],
    experienceTaxonomy: ExperienceTaxonomy[]
  ) {
    return super.write<{ data: { experienceTaxonomy: ExperienceTaxonomy[] } }>({
      endpoint: `/candidates/${candidateId}/experiences/${id}/taxonomy`,
      method: 'PUT',
      body: { experienceTaxonomy },
    });
  }

  createExperienceFocusAreaPredictions(id: Experience['id']) {
    return super.write<{ data: ExperienceTaxonomyFocusArea[] }>({
      endpoint: `/experiences/${id}/suggestedFocusAreas`,
      method: 'POST',
    });
  }

  createExperienceLegalSkillPredictions(id: Experience['id']) {
    return super.write<{ data: ExperienceTaxonomyLegalSkill[] }>({
      endpoint: `/experiences/${id}/suggestedLegalSkills`,
      method: 'POST',
    });
  }
}

export const ExperienceApi = new ExperienceApiClass();
