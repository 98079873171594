import React from 'react';
import { ProfileCompletionConst } from '@axiom/const';

import { ProfileCompletionBanner } from '../ProfileCompletionBanner/ProfileCompletionBanner';

export const LanguagesBannerPrompt = ({
  onOpenEdit = null,
}: {
  onOpenEdit?: () => void;
}) => {
  return (
    <ProfileCompletionBanner
      items={[
        {
          title:
            ProfileCompletionConst.ProfileCompletionLanguagesBanner
              .missingTitle,
          description:
            ProfileCompletionConst.ProfileCompletionLanguagesBanner
              .missingContent,
          estimatedTime: '5 min',
        },
      ]}
      onOpenEdit={onOpenEdit}
      name="LANGUAGES_BANNER_PROMPT"
    />
  );
};
