import { Api } from '@axiom/ui';
import { MfaPhoneNumber, MfaVerification, User } from '@axiom/validation';

import { EnvUtil } from '../utils/env-util';

class UsersApiClass extends Api {
  constructor() {
    super({
      domain: EnvUtil.apiPortalUrl,
    });
  }

  updateMfaPhoneNumber(userId: string, body: MfaPhoneNumber) {
    return super.write<{ data: MfaPhoneNumber }>({
      endpoint: `/users/${userId}/mfaPhoneNumber`,
      method: 'PUT',
      body,
    });
  }

  createMfaVerification(userId: string, body: MfaVerification) {
    return super.write<{ data: string }>({
      endpoint: `/users/${userId}/mfaVerification`,
      method: 'POST',
      body,
    });
  }

  updateUser(user: User) {
    return super.write<{ data: User }>({
      endpoint: `/users/${user.id}`,
      method: 'PATCH',
      body: user,
    });
  }
}

export const UsersApi = new UsersApiClass();
