import { groupBy, uniq } from 'lodash';
import {
  CandidateLanguage,
  CandidateLanguageForm,
  CandidateLanguageUpdate,
} from '@axiom/validation';
import { LanguageConst } from '@axiom/const';

import { capitalizeString } from './string-util';

const { LANGUAGE_SKILLS } = LanguageConst;

export const CandidateLanguageUtil = {
  /**
   * Filter specified language from list of languages
   * @param languages
   * @param languageToRemove
   */
  filterActionedLanguage: (
    languages: CandidateLanguage[],
    languageToRemove?: CandidateLanguageForm
  ) => {
    let copy = [...languages];
    if (!languageToRemove) {
      return copy;
    }
    const { id, languageProficiency, languageCEFR } = languageToRemove;

    if (id || languageProficiency || languageCEFR) {
      copy = languages.filter(
        lang =>
          lang.id !== id ||
          lang.languageProficiency !== languageProficiency ||
          lang.languageCEFR !== languageCEFR
      );
    }

    return copy;
  },

  /**
   * format candidate languages into expected body format for PUT call
   * @param languagePayload
   */
  normalizeCandidateLanguages: (
    languagePayload: CandidateLanguage[]
  ): CandidateLanguageUpdate[] => {
    return languagePayload.map(lang => ({
      languageId: lang.id,
      languageSkill: lang.languageSkill,
      languageProficiency: lang.languageProficiency,
      languageCEFR: lang.languageCEFR || null,
    }));
  },

  /**
   * candidateLanguages here should share the same id, CEFR, and proficiency
   * skills are combined into an array
   * this is used for candidate language forms
   * @param candidateLanguages
   */
  formatLanguagesForForm: (
    candidateLanguages: CandidateLanguage[]
  ): CandidateLanguageForm | undefined => {
    const language =
      candidateLanguages.length > 0 ? candidateLanguages[0] : undefined;
    const languageSkills = uniq(
      candidateLanguages.map(l => l.languageSkill)
    ).filter(
      skill => skill !== null
    ) as CandidateLanguageForm['languageSkills'];

    if (language) {
      return {
        id: language.id,
        languageCEFR: language.languageCEFR,
        languageProficiency: language.languageProficiency,
        languageSkills: language.id
          ? languageSkills
          : (Object.keys(
              LANGUAGE_SKILLS
            ) as CandidateLanguageForm['languageSkills']),
      };
    }

    return undefined;
  },

  /**
   * Converts languages into object grouped by languages and further categorized
   * by name, languageProficiency, and languageCEFR
   * @param languages
   */
  groupByCategories(languages: CandidateLanguage[]) {
    const groupedByName = groupBy(languages, lang => lang.name);
    const languageNames = Object.keys(groupedByName);

    return languageNames.reduce(
      (acc, langName) => {
        acc[langName] = groupBy(
          groupedByName[langName],
          lang =>
            `n:${lang.name}:p:${lang.languageProficiency}:c:${lang.languageCEFR}`
        );
        return acc;
      },
      {} as Partial<{ [key: string]: Record<string, CandidateLanguage[]> }>
    );
  },

  /**
   * Displays a grouping of languages, formatting as `Language Proficiency • Skill1, Skill2, etc...`
   * @param languages
   */
  buildLanguageDisplayBars(languages: CandidateLanguage[]) {
    if (languages.length > 0) {
      const firstLanguage = languages[0] as CandidateLanguage;

      return [
        capitalizeString(
          LanguageConst.LANGUAGE_PROFICIENCIES[
            firstLanguage.languageProficiency
          ]
        ),
        languages
          .reduce((langs, current) => {
            if (current.languageSkill) {
              langs.push(capitalizeString(current.languageSkill));
            }
            return langs;
          }, [] as string[])
          .join(', '),
      ].filter(Boolean);
    }
    return [];
  },
};
