import React from 'react';
import {
  Button,
  Modal,
  ModalSection,
  ModalHeader,
  ModalFooter,
} from '@axiom/ui';

export type ExperienceEditDataLossWarningModalType = {
  onClose: () => void;
  onContinue: () => void;
};

export const ExperienceEditDataLossWarningModal = ({
  onClose,
  onContinue,
}: ExperienceEditDataLossWarningModalType) => {
  return (
    <Modal name="EXPERIENCE_EDIT_DATA_LOSS_WARNING_MODAL" size="confirm">
      <ModalHeader
        name="EXPERIENCE_EDIT_DATA_LOSS_WARNING_MODAL_HEADER"
        onClose={() => onClose()}
      >
        Unsaved changes
      </ModalHeader>
      <ModalSection>
        Some details in this section have not been saved. Go back to save your
        changes or continue to close this section without saving your changes.
      </ModalSection>
      <ModalFooter>
        <Button
          name="EXPERIENCE_EDIT_DATA_LOSS_WARNING_MODAL_CANCEL"
          variation="outline"
          onClick={() => onClose()}
        >
          Go Back
        </Button>
        <Button
          name="EXPERIENCE_EDIT_DATA_LOSS_WARNING_MODAL_CONTINUE"
          onClick={() => onContinue()}
        >
          Continue
        </Button>
      </ModalFooter>
    </Modal>
  );
};
