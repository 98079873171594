import React, { useState, useEffect } from 'react';
import {
  Button,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalSection,
  Paragraph,
  Gutter,
  Form,
  Grid,
  GridRow,
  GridColumn,
  Input,
  ToastUtil,
  CondensedLarge,
  ApiError,
  NotFoundError,
  BadRequestError,
} from '@axiom/ui';
import {
  User,
  Candidate,
  MfaPhoneNumber,
  MfaVerificationSchema,
} from '@axiom/validation';

import { UsersApi } from '../../api/users-api';
import { CandidateApi } from '../../api/candidate-api';

export interface TwoFactorVerificationModalProps {
  onClose: () => void;
  user: User;
  candidateMfa: MfaPhoneNumber;
  candidate: Candidate;
}

export const PreferencesTwoFactorVerifyModal = ({
  onClose,
  user,
  candidate,
  candidateMfa,
}: TwoFactorVerificationModalProps) => {
  const [isResendHidden, setIsResendHidden] = useState(true);
  const [timeoutCommand, setTimeoutCommand] = useState(Date.now());

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsResendHidden(false);
    }, 120000);

    // Cleanup function to clear the timeout on unmount or when dependencies change
    return () => clearTimeout(timeoutId);
  }, [timeoutCommand]);

  return (
    <Form
      schema={MfaVerificationSchema}
      initialValues={{
        mfaCode: null,
      }}
      onSubmit={async (formData, actions) => {
        try {
          await UsersApi.createMfaVerification(
            user.id,
            MfaVerificationSchema.parse(formData)
          );
          await CandidateApi.refreshCandidateMfaPhoneNumber(candidate.id);
          ToastUtil.add({
            name: 'VERIFICATION_CODE_SUCCESS',
            type: 'info',
            children: (
              <CondensedLarge>
                You have successfully added a 2-factor authentication phone
                number
              </CondensedLarge>
            ),
          });

          onClose();
        } catch (error) {
          if (error instanceof ApiError) {
            if (error instanceof BadRequestError) {
              await actions.setFieldValue('mfaCode', null);
              actions.setFieldError(
                'mfaCode',
                'Invalid Code. Please request another one below.'
              );
            } else if (error instanceof NotFoundError) {
              await actions.setFieldValue('mfaCode', null);
              actions.setFieldError(
                'mfaCode',
                'Code Expired. Please request another one below.'
              );
            }
            setTimeoutCommand(Date.now());
            setIsResendHidden(false);
          } else {
            throw error;
          }
        }
      }}
    >
      {({ fireSubmit, setValues }) => {
        return (
          <Modal name="TWO_FACTOR_VERIFICATION_MODAL">
            <ModalHeader
              onClose={() => onClose()}
              name="TWO_FACTOR_VERIFICATION_MODAL_HEADER"
            >
              Two-factor authentication
            </ModalHeader>
            <ModalSection>
              <Paragraph>
                We have sent a temporary code to{' '}
                {`+${candidateMfa.countryCode} ${candidateMfa.phoneNumber}`}.
                Enter the code below to verify that it's you. This code will
                expire after two minutes.
              </Paragraph>
              <Gutter bottom="16px" />
              <Grid>
                <GridRow>
                  <GridColumn
                    largeScreenWidth={6}
                    tabletWidth={6}
                    mobileWidth={12}
                  >
                    <Input name="mfaCode" label="Enter the code" />
                    <Gutter bottom="16px" />
                    {!isResendHidden && (
                      <Button
                        name="RESEND_CODE"
                        variation="outline"
                        onClick={async () => {
                          await UsersApi.updateMfaPhoneNumber(user.id, {
                            phoneNumber: candidateMfa.phoneNumber,
                            countryCode: candidateMfa.countryCode,
                          });
                          setIsResendHidden(true);
                          ToastUtil.add({
                            name: 'VERIFICATION_CODE_RESEND',
                            type: 'info',
                            children: (
                              <CondensedLarge>
                                Code Sent to{' '}
                                {`+${candidateMfa.countryCode} ${candidateMfa.phoneNumber}`}
                              </CondensedLarge>
                            ),
                          });
                          setTimeoutCommand(Date.now());
                          await setValues({ mfaCode: null });
                        }}
                      >
                        Resend Code
                      </Button>
                    )}
                  </GridColumn>
                  <GridColumn width={6} />
                </GridRow>
              </Grid>
            </ModalSection>
            <ModalFooter>
              <Button
                name="CANCEL_BUTTON"
                variation="outline"
                onClick={() => onClose()}
              >
                Cancel
              </Button>
              <Button name="VERIFY_BUTTON" onClick={fireSubmit}>
                Verify
              </Button>
            </ModalFooter>
          </Modal>
        );
      }}
    </Form>
  );
};
