import { orderBy } from 'lodash';
import { Certification } from '@axiom/validation';
import { CertificationModel } from '@axiom/ui-models';

export const CertificationsUtil = {
  groupCertifications: (certifications?: Certification[] | null) => {
    const structure = {
      certifications: [] as Array<Certification>,
      qualifications: [] as Array<Certification>,
    };
    if (!certifications) {
      return structure;
    }
    const sorted = orderBy(certifications, ['year'], ['desc']);
    return sorted.reduce((acc, cert) => {
      const certModel = new CertificationModel(cert);
      if (certModel.isQualification()) {
        acc.qualifications.push(cert);
      } else {
        acc.certifications.push(cert);
      }
      return acc;
    }, structure);
  },
};
