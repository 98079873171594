export const CONFIDENTIAL_COMPANY = 'Confidential Company';
export const AXIOM_CLIENT = 'Axiom Client';

export const Experience = {
  /** field names */
  CANDIDATE_ID: 'candidateId',
  CLIENT: 'client',
  CLIENT_DOMAIN: 'clientDomain',
  CREATED_AT: 'createdAt',
  DESCRIPTION: 'description',
  END_DATE: 'endDate',
  EXTERNAL_OPPORTUNITY_NAME: 'externalOpportunityName',
  ID: 'id',
  IS_AXIOM: 'isAxiom',
  IS_CONFIDENTIAL: 'isConfidential',
  LOCATION_ADDRESS_COMPONENTS: 'locationAddressComponents',
  LOCATION_NAME: 'locationName',
  LOCATION_PLACE_ID: 'locationPlaceId',
  OPPORTUNITY_ID: 'opportunityId',
  START_DATE: 'startDate',
  UPDATED_AT: 'updatedAt',
} as const;

export const CandidateOpportunityExperience = {
  IS_HIGHLIGHTED: 'isHighlighted',
  EXPERIENCE_VISIBLE: 'experienceVisible',
  SUBMISSION_DESCRIPTION: 'submissionDescription',
} as const;
