import { Api } from '@axiom/ui';
import { Education, EducationEdit } from '@axiom/validation';

import { EnvUtil } from '../utils/env-util';

class EducationApiClass extends Api {
  constructor() {
    super({
      domain: EnvUtil.clientApiBase,
      cookieDomain: EnvUtil.cookieDomain,
    });
  }

  updateEducation(id: Education['id'], education: Partial<EducationEdit>) {
    return super.write<{ data: Education }>({
      endpoint: `/education/${id}`,
      body: education,
      method: 'PATCH',
    });
  }

  deleteEducation(id: Education['id']) {
    return super.write<{ data: Education }>({
      endpoint: `/education/${id}`,
      method: 'DELETE',
    });
  }
}

export const EducationApi = new EducationApiClass();
