import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, GridColumn, Gutter, useApi } from '@axiom/ui';

import { UserApi } from '../../api/user-api';
import { TaxonomyApi } from '../../api/taxonomy-api';
import { CandidateUtil } from '../../utils/candidate-util';
import { CandidateProfileIssuesApi } from '../../api/candidate-profile-issues-api';
import { CandidateExperienceApi } from '../../api/candidate-experience-api';
import { CandidateLegacyApi } from '../../api/candidate-legacy-api';

import {
  ExperienceEditSectionKeys,
  ExperienceEditUtil,
} from './experience-edit-util';
import { ExperienceEditContext } from './ExperienceEditContext';
import { ExperienceEditHeader } from './ExperienceEditHeader';
import { ExperienceEditTips } from './ExperienceEditTips';
import {
  ExperienceEditCompanyDetailsAxiom,
  CompanyDetailsAxiomSchema,
} from './ExperienceEditCompanyDetailsAxiom';
import {
  ExperienceEditCompanyDetailsNonAxiom,
  CompanyDetailsNonAxiomSchema,
} from './ExperienceEditCompanyDetailsNonAxiom';
import { ExperienceEditFormWorkDetails } from './ExperienceEditFormWorkDetails';
import { ExperienceEditFormFocusAreas } from './ExperienceEditFormFocusAreas';
import { ExperienceEditFormSkills } from './ExperienceEditFormSkills';
import { FocusAreasSectionSchema } from './experience-edit-focus-areas-schema';

const { COMPANY_DETAILS, WORK_DETAILS, FOCUS_AREAS, SKILLS } =
  ExperienceEditSectionKeys;

export const ExperienceEdit = () => {
  const { experienceId, candidateId: urlCandidateId } = useParams();
  const [{ data: user }, { data: taxonomy }] = useApi(
    UserApi.readUser(),
    TaxonomyApi.readTaxonomy()
  );
  const candidateId = CandidateUtil.reconcileUserCandidateId(
    user,
    urlCandidateId
  );
  const [
    { data: candidate },
    { data: profileIssues },
    { data: experiences } = { data: [] },
  ] = useApi(
    CandidateLegacyApi.readCandidate(candidateId),
    CandidateProfileIssuesApi.readCandidateProfileIssues(candidateId),
    experienceId
      ? CandidateExperienceApi.readExperiences(candidateId)
      : undefined
  );
  const experience = experiences.find(exp => exp.id === experienceId) ?? {};

  const companyDetailsComplete = ExperienceEditUtil.isDataValid(
    experience.isAxiom
      ? CompanyDetailsAxiomSchema
      : CompanyDetailsNonAxiomSchema,
    experience
  );
  const workDetailsComplete = ExperienceEditUtil.isWorkDetailsComplete(
    experience,
    profileIssues.issues
  );
  const focusAreasComplete = ExperienceEditUtil.isDataValid(
    FocusAreasSectionSchema,
    experience
  );
  const skillsComplete = ExperienceEditUtil.isSkillsComplete(
    experience.experienceTaxonomy
  );

  const [accordionState, setAccordionState] = useState({
    [COMPANY_DETAILS]: !companyDetailsComplete,
    [WORK_DETAILS]: companyDetailsComplete && !workDetailsComplete,
    [FOCUS_AREAS]:
      companyDetailsComplete && workDetailsComplete && !focusAreasComplete,
    [SKILLS]:
      companyDetailsComplete &&
      workDetailsComplete &&
      focusAreasComplete &&
      !skillsComplete,
  });

  const [dirtyFormState, setDirtyFormState] = useState({
    [COMPANY_DETAILS]: false,
    [WORK_DETAILS]: false,
    [FOCUS_AREAS]: false,
    [SKILLS]: false,
  });

  const providerValue = useMemo(
    () => ({
      accordionState,
      setAccordionState,
      dirtyFormState,
      setDirtyFormState,
    }),
    [accordionState, dirtyFormState]
  );

  return (
    <div data-test="EXPERIENCE_EDIT">
      <ExperienceEditContext.Provider value={providerValue}>
        <ExperienceEditHeader experience={experience} />
        <Gutter bottom="24px" />
        <Grid>
          <GridColumn smallScreenWidth={12} largeScreenWidth={8}>
            {experience.isAxiom ? (
              <ExperienceEditCompanyDetailsAxiom
                candidateId={candidateId}
                experience={experience}
                issues={profileIssues.issues}
                taxonomy={taxonomy}
              />
            ) : (
              <ExperienceEditCompanyDetailsNonAxiom
                candidateId={candidateId}
                experience={experience}
                issues={profileIssues.issues}
                taxonomy={taxonomy}
              />
            )}
            <Gutter bottom="16px" />
            <ExperienceEditFormWorkDetails
              candidateId={candidateId}
              expandable={companyDetailsComplete}
              experience={experience}
              issues={profileIssues.issues}
            />
            <Gutter bottom="16px" />
            <ExperienceEditFormFocusAreas
              candidate={candidate}
              expandable={!!companyDetailsComplete && workDetailsComplete}
              experience={experience}
              taxonomy={taxonomy}
            />
            <Gutter bottom="16px" />
            <ExperienceEditFormSkills
              candidate={candidate}
              expandable={
                companyDetailsComplete &&
                workDetailsComplete &&
                focusAreasComplete
              }
              experience={experience}
              taxonomy={taxonomy}
            />
            <Gutter bottom="24px" />
          </GridColumn>
          <GridColumn smallScreenWidth={12} largeScreenWidth={4}>
            <ExperienceEditTips experience={experience} />
          </GridColumn>
        </Grid>
      </ExperienceEditContext.Provider>
    </div>
  );
};
