import { string, number, shape, arrayOf, bool } from 'prop-types';
import { Experience } from '@axiom/const';

export const ExperienceDataShape = shape({
  [Experience.CANDIDATE_ID]: string,
  [Experience.ID]: string,
  isAxiom: bool,
  [Experience.CLIENT]: string,
  [Experience.DESCRIPTION]: string,
  submissionDescription: string,
  [Experience.START_DATE]: string,
  [Experience.END_DATE]: string,
  [Experience.CREATED_AT]: string,
  [Experience.UPDATED_AT]: string,
  [Experience.OPPORTUNITY_ID]: string,
  [Experience.EXTERNAL_OPPORTUNITY_NAME]: string,
  [Experience.LOCATION_ADDRESS_COMPONENTS]: shape({}),
  [Experience.LOCATION_NAME]: string,
  [Experience.LOCATION_PLACE_ID]: string,
  [Experience.CLIENT_DOMAIN]: string,
  skills: arrayOf(
    shape({
      id: string,
      name: string,
      createdAt: string,
      updatedAt: string,
    })
  ),
});
