import React from 'react';
import { Brief, CondensedLarge, Gutter } from '@axiom/ui';

export const ExperienceEditDescriptionGuidelines = () => {
  return (
    <div data-test="EXPERIENCE_EDIT_DESCRIPTION_GUIDELINES">
      <Brief briefMaxChildren={2} pattern="secondary">
        <CondensedLarge>
          For experiences within the last 10 years:
          <ul>
            <li>
              If the experience was longer than 9 months, write at least 3
              bullet points and 300 characters.
            </li>
            <li>
              If the experience was shorter than 9 months, write at least 1
              bullet point and 100 characters.
            </li>
          </ul>
        </CondensedLarge>
        <CondensedLarge>
          For experiences older than 10 years:
          <ul>
            <li>Write at least 1 bullet point and 100 characters.</li>
          </ul>
        </CondensedLarge>
        <Gutter bottom="16px">
          <CondensedLarge>
            Only include experiences that are relevant to getting Axiom
            engagements.
          </CondensedLarge>
        </Gutter>
        <Gutter bottom="16px">
          <CondensedLarge>
            Describe your work using comprehensive but concise bullet points.
            For example, specify contract types you reviewed, drafted, or
            negotiated. Highlight projects you successfully completed.
          </CondensedLarge>
        </Gutter>
        <Gutter bottom="16px">
          <CondensedLarge>
            Craft descriptions that are precise, specific, and accurate. Avoid
            vague descriptions like "Vendor/Tech agreements" or "L&E support."
          </CondensedLarge>
        </Gutter>
        <Gutter bottom="16px">
          <CondensedLarge>
            Apply consistent headings, spacing, and bullet points for a uniform
            appearance.
          </CondensedLarge>
        </Gutter>
        <Gutter bottom="16px">
          <CondensedLarge>
            Use strong verbs to clearly describe the engagement's core
            responsibilities and workstreams.
          </CondensedLarge>
        </Gutter>
        <Gutter bottom="16px">
          <CondensedLarge>
            Maintain consistent verb tense when describing past work
            experiences. Avoid inconsistencies such as "Drafting contracts" and
            "Assisted on procurement contracts."
          </CondensedLarge>
        </Gutter>
      </Brief>
    </div>
  );
};
