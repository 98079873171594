import { LocalStorage } from '@axiom/ui';
import { Candidate } from '@axiom/validation';

const STORE_NAMES = {
  workFeedFilters: 'work_feed_filters',
};

export const localStorageUtil = {
  workFeedFilters: (
    candidateId: Candidate['id'],
    practiceAreaId: Array<string> | null = [],
    countries: Array<string> | null = [],
    talentTypes: Array<string> | null = []
  ) => {
    if (!candidateId) {
      throw new Error('candidateId is required');
    }

    return LocalStorage(`${STORE_NAMES.workFeedFilters}:${candidateId}:v3`, {
      practiceAreaId,
      countries,
      talentTypes,
    });
  },
};
