import React from 'react';
import { Experience } from '@axiom/validation';
import { Gutter, ReadonlyTextarea, TabsItem, Tabs } from '@axiom/ui';

import { ExperienceEditDescriptionGuidelines } from './ExperienceEditDescriptionGuidelines';

export type ExperienceEditTipsProps = {
  experience?: Experience;
};

export const ExperienceEditTips = ({ experience }: ExperienceEditTipsProps) => {
  const tabs = [
    <TabsItem label="Guidelines" name="GUIDELINES" key="GUIDELINES">
      <Gutter top="16px">
        <ExperienceEditDescriptionGuidelines />
      </Gutter>
    </TabsItem>,
    experience?.opportunity?.position?.description && (
      <TabsItem
        label="Job Description"
        name="JOB_DESCRIPTION"
        key="JOB_DESCRIPTION"
      >
        <Gutter top="16px">
          <ReadonlyTextarea
            name="position_description"
            value={experience.opportunity.position.description}
          />
        </Gutter>
      </TabsItem>
    ),
  ].filter(Boolean);

  return (
    <Tabs name="EXPERIENCE_EDIT_TIPS" fluid>
      {tabs}
    </Tabs>
  );
};
