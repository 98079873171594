import React, { useContext, useEffect, useState } from 'react';
import { Candidate, Experience, Taxonomy } from '@axiom/validation';
import { Loader, Suspense } from '@axiom/ui';

import { ExperienceApi } from '../../api/experience-api';
import { CandidateExperienceApi } from '../../api/candidate-experience-api';

import {
  ExperienceEditSectionKeys,
  ExperienceEditUtil,
  minimumDescriptionLengthToRetrieveSuggestions,
} from './experience-edit-util';
import { ExperienceEditContext } from './ExperienceEditContext';
import { ExperienceEditSection } from './ExperienceEditSection';
import { ExperienceEditFormSkillsBody } from './ExperienceEditFormSkillsBody';

const { SKILLS } = ExperienceEditSectionKeys;

export type ExperienceEditFormSkillsProps = {
  candidate: Candidate;
  expandable?: boolean;
  experience: Experience;
  taxonomy: Taxonomy;
};

export const ExperienceEditFormSkills = ({
  candidate,
  expandable,
  experience,
  taxonomy,
}: ExperienceEditFormSkillsProps) => {
  const { accordionState } = useContext(ExperienceEditContext);

  const { experienceTaxonomy = [] } = experience;

  const isSectionComplete =
    ExperienceEditUtil.isSkillsComplete(experienceTaxonomy);

  const shouldGetDsSuggestedLegalSkills =
    !experience.suggestedLegalSkillsRetrievedAt &&
    experience.description?.length >=
      minimumDescriptionLengthToRetrieveSuggestions &&
    experience.experiencesFocusAreaOrderPreferences?.length > 0;

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchAndRefresh = async () => {
      setIsLoading(true);
      await ExperienceApi.createExperienceLegalSkillPredictions(experience.id);
      await CandidateExperienceApi.refreshExperiences(candidate.id);
      setIsLoading(false);
    };

    if (shouldGetDsSuggestedLegalSkills) {
      fetchAndRefresh();
    }
  }, [experience.experiencesFocusAreaOrderPreferences]);

  return (
    <ExperienceEditSection
      expandable={expandable}
      expanded={accordionState[SKILLS]}
      isComplete={isSectionComplete}
      title="Skills"
      name={SKILLS}
    >
      <Suspense
        pendingFallback={
          <Loader type="bars" name="EXPERIENCE_EDIT_FORM_SKILLS_LOADER" />
        }
        isPending={isLoading}
      >
        {!isLoading && (
          <ExperienceEditFormSkillsBody
            candidate={candidate}
            experience={experience}
            taxonomy={taxonomy}
          />
        )}
      </Suspense>
    </ExperienceEditSection>
  );
};
