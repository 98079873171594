import {
  ExperienceEditSchema,
  Taxonomy,
  TaxonomyFocusAreaEnum,
  TaxonomyLegalSkillEnum,
} from '@axiom/validation';
import { z } from 'zod';
import { ZodArrayToEnum } from '@axiom/types';

const pickedExperienceEditSchema = ExperienceEditSchema.pick({
  generalSkills: true,
});

export type dynamicSchemaType = z.infer<typeof pickedExperienceEditSchema> &
  Record<TaxonomyFocusAreaEnum, TaxonomyLegalSkillEnum[]>;

export const getDynamicFormSkillsSchema = (
  focusAreaOrder: TaxonomyFocusAreaEnum[],
  taxonomy: Taxonomy
) => {
  const legalSkillEnums: TaxonomyLegalSkillEnum[] = Object.keys(
    taxonomy.legalSkills
  );

  return pickedExperienceEditSchema
    .passthrough() // allows dynamic properties to be check against in refine
    .extend({
      ...focusAreaOrder.reduce(
        (acc, curr) => {
          acc[curr] = z.array(z.enum(ZodArrayToEnum(legalSkillEnums)));
          return acc;
        },
        {} as {
          [key: TaxonomyFocusAreaEnum]: z.ZodArray<
            z.ZodEnum<[TaxonomyLegalSkillEnum, ...TaxonomyLegalSkillEnum[]]>
          >;
        }
      ),
    });
};
