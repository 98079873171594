import React from 'react';
import { FormFieldProps } from 'semantic-ui-react';
import { SchemaTypes } from '@axiom/types';

import { FormField } from '../FormField/FormField';

import {
  RawSortable,
  RawSortableProps,
  RawSortableValueType,
} from './RawSortable';

export type SortableProps = {
  description?: string;
  displayKey: RawSortableProps['displayKey'];
  id?: RawSortableProps['id'];
  label?: React.ReactNode;
  name: RawSortableProps['name'];
  onChange?: (value?: RawSortableValueType) => void;
  options: RawSortableProps['options'];
  valueKey: RawSortableProps['valueKey'];
};
const noopFn = () => {};
export const Sortable = ({
  description,
  displayKey,
  id,
  label,
  name,
  onChange = noopFn,
  options,
  valueKey,
}: SortableProps) => {
  id = id || name;
  return (
    <FormField
      {...{
        description,
        id,
        name,
        label,
      }}
      renderField={({
        setFieldValue,
        schemaProperty,
        value,
      }: FormFieldProps & { value: RawSortableValueType }) => {
        if (schemaProperty.type !== SchemaTypes.ZodArray) {
          throw new Error(`Options for '${name}' must be an array of values`);
        }
        if (!value) {
          throw new Error(
            `Must provide initial values for '${name}' which correspond to an array of valueKey values from options objects`
          );
        }
        return (
          <RawSortable
            displayKey={displayKey}
            id={id}
            name={name}
            onChange={v => {
              onChange(v);
              setFieldValue(name, v);
            }}
            options={options}
            value={value}
            valueKey={valueKey}
          />
        );
      }}
    />
  );
};
