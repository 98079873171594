import React from 'react';
import moment from 'moment-timezone';
import {
  Calendar as CalendarType,
  CalendarEventType,
  Candidate,
  User,
  TimeZone,
} from '@axiom/validation';
import {
  Grid,
  GridColumn,
  GridRow,
  ImageCircle,
  Layout,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalSection,
  Paragraph,
  SmallHeader,
  Calendar,
  CalendarPermissionsUtil,
  Button,
  LayoutItem,
  Gutter,
  Dropdown,
  Form,
  CalendarTimeZoneUtil,
  useBreakpoint,
  CandidateProfileUtil,
  CalendarEventsUtil,
  EventStates,
} from '@axiom/ui';
import {
  PossibleImageSizes,
  PermissionImpersonationRoles,
  NO_TIMEZONE,
} from '@axiom/const';

import { CalendarApi } from '../../api/calendar-api';
import { CandidateLegacyApi } from '../../api/candidate-legacy-api';

import {
  CalendarFormType,
  CalendarFormSchema,
} from './interview-availability-modal-types';

const dayWeekMode = {
  slots: 2,
  increments: 30,
};

export type InterviewAvailabilityModalType = {
  candidate: Candidate;
  calendarData: CalendarType;
  user: User;
  onClose: () => void;
};

export const InterviewAvailabilityModal = ({
  candidate,
  calendarData,
  user,
  onClose,
}: InterviewAvailabilityModalType) => {
  const { isMobile } = useBreakpoint();
  const guessIana = moment.tz.guess();
  const { id: calendarId = null, timezone: talentIana } =
    candidate.calendar || {};
  const timezone = CalendarTimeZoneUtil.getTimeZone(talentIana || guessIana);

  const { userPermissions } = CalendarPermissionsUtil(
    user,
    PermissionImpersonationRoles.talent
  );

  const calendarPermissions = userPermissions();

  const timezoneOptions = CalendarTimeZoneUtil.getDropDownOptions();

  const initValues = {
    CALENDAR_COMPONENT: (
      calendarData.events?.filter(
        event =>
          !CalendarEventsUtil.isInThePast(event.endTime, calendarPermissions)
      ) || []
    ).map(e => {
      e.state = e.state ?? EventStates.SAVED;
      return e;
    }),
    TIMEZONE_DROPDOWN: timezone === NO_TIMEZONE ? null : timezone.id,
  };

  const refreshData = async () => {
    await Promise.all([
      CalendarApi.refreshCalendar(calendarId),
      CalendarApi.refreshCalendarEvents(calendarId),
      new Promise<void>(resolve => {
        setTimeout(() => {
          resolve();
          CandidateLegacyApi.refreshCandidate(candidate.id);
        }, 1500);
      }),
    ]);
  };

  const handleUpdateOnClick = async (
    { CALENDAR_COMPONENT, TIMEZONE_DROPDOWN }: CalendarFormType,
    {
      setFieldError,
    }: { setFieldError: (field: string, message: string) => void }
  ) => {
    const cleanEvents = CalendarEventsUtil.cleanAndOrganiseEvents(
      calendarPermissions,
      initValues.CALENDAR_COMPONENT,
      CALENDAR_COMPONENT
    );

    if (
      cleanEvents === null ||
      Object.values(cleanEvents).flat().length === 0
    ) {
      // no event changes aka calendar is required
      setFieldError(
        'CALENDAR_COMPONENT',
        'Please make at least one edit to continue.'
      );

      return;
    }

    const { iana }: TimeZone = TIMEZONE_DROPDOWN
      ? CalendarTimeZoneUtil.getZoneFromId(TIMEZONE_DROPDOWN)
      : timezone;

    let currentCalendarId = calendarId;
    if (!currentCalendarId) {
      const newCalendar = await CalendarApi.createCalendar({
        candidateId: candidate.id,
        timezone: timezone.iana,
      });
      currentCalendarId = newCalendar.data.id;
    }
    const promises = [];

    // These API calls are in specific order to prevent race-conditions ==================== START
    if (cleanEvents.deleteIds.length > 0) {
      await CalendarApi.deleteCalendarEvents(
        currentCalendarId,
        cleanEvents.deleteIds
      );
    }

    if (cleanEvents.modifiedEvents.length > 0) {
      const payload = cleanEvents.modifiedEvents.reduce(
        (crnt, event) => {
          const { id, ...evnt } = event;
          crnt[id] = evnt;
          return crnt;
        },
        {} as { [key: string]: CalendarEventType }
      );

      promises.push(
        CalendarApi.updateCalendarEvents(currentCalendarId, payload)
      );
    }

    if (cleanEvents.newEvents.length > 0) {
      promises.push(
        CalendarApi.createCalendarEvents(
          currentCalendarId,
          cleanEvents.newEvents
        )
      );
    }

    await Promise.all(promises);

    if (TIMEZONE_DROPDOWN) {
      // API call not part of the Promise.all() above to avoid race-condition
      await CalendarApi.updateCalendar(currentCalendarId, {
        timezone: iana,
      });
    }
    // These API calls are in specific order to prevent race-conditions ==================== END

    await refreshData();
    onClose();
  };

  return (
    timezone && (
      <Form
        name="INTERVIEW_AUTO_FORM"
        schema={CalendarFormSchema}
        initialValues={initValues}
        onSubmit={handleUpdateOnClick}
      >
        {({ fireSubmit, values }) => {
          return (
            <Modal size="large" name="INTERVIEW_AUTO_MODAL">
              <ModalHeader name="MODAL_HEADER" onClose={onClose}>
                <Layout position="middle" wrap>
                  <LayoutItem rightGutter="16px">
                    <ImageCircle
                      imageName={candidate.calculatedDisplayName}
                      src={CandidateProfileUtil.getProfileImageUri(
                        candidate,
                        PossibleImageSizes.W_100
                      )}
                      size="small"
                      name="TALENT_IMAGE"
                    />
                  </LayoutItem>
                  <LayoutItem fluid>
                    <SmallHeader name="PENDO_TALENT_INTERVIEW_AVAILABILITY">
                      Update your interview availability
                    </SmallHeader>
                  </LayoutItem>
                </Layout>
              </ModalHeader>
              <ModalSection>
                <Grid>
                  <GridRow>
                    <GridColumn widescreenWidth={4} smallScreenWidth={12}>
                      <Paragraph>
                        Choose as many time slots as possible to increase the
                        likelihood of being matched for an interview. We suggest
                        a minimum 4 hours over the next two weeks.
                      </Paragraph>
                      <Gutter bottom="24px" />
                      <Dropdown
                        label="Time zone"
                        options={timezoneOptions}
                        name="TIMEZONE_DROPDOWN"
                        displayKey="label"
                        valueKey="value"
                      />
                      <Gutter bottom="16px" />
                    </GridColumn>
                    <GridColumn widescreenWidth={8} smallScreenWidth={12}>
                      <Calendar
                        name="CALENDAR_COMPONENT"
                        permissions={calendarPermissions}
                        dayMode={dayWeekMode}
                        weekMode={dayWeekMode}
                        timezone={
                          values.TIMEZONE_DROPDOWN
                            ? CalendarTimeZoneUtil.getZoneFromId(
                                values.TIMEZONE_DROPDOWN
                              ).iana
                            : timezone.iana
                        }
                      />
                    </GridColumn>
                  </GridRow>
                </Grid>
              </ModalSection>
              <ModalFooter>
                <Button
                  onClick={onClose}
                  pattern="primary"
                  variation="outline"
                  name="CANCEL_BUTTON"
                >
                  Cancel
                </Button>
                {!isMobile && (
                  <Button onClick={fireSubmit} name="UPDATE_BUTTON">
                    Update
                  </Button>
                )}
              </ModalFooter>
            </Modal>
          );
        }}
      </Form>
    )
  );
};
