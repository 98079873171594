import { z } from 'zod';
import { ExperienceSchema } from '@axiom/validation';

export const FocusAreasSectionSchema = ExperienceSchema.pick({
  experiencesFocusAreaOrderPreferences: true,
});

export const FocusAreasFormSchema = z.object({
  focusAreas: z
    .array(z.string())
    .min(1, 'Please select at least one focus area'),
});
