import React, { useContext, useMemo, useState } from 'react';
import {
  Accordions,
  AccordionsItem,
  AccordionsItemHeader,
  AccordionsItemSection,
  Badge,
  Button,
  Checkbox,
  CondensedHeader,
  CondensedLarge,
  FluidButtonLayout,
  Form,
  FormGroup,
  Gutter,
  Layout,
  LayoutItem,
  Paragraph,
  SectionHeader,
  TaxonomyUtil,
  ToastUtil,
  Well,
  useBreakpoint,
  CandidateTaxonomyUtil,
} from '@axiom/ui';
import { ProfessionConst } from '@axiom/const';
import {
  Candidate,
  Experience,
  Taxonomy,
  TaxonomyFocusAreaEnum,
  TaxonomyLegalSkillEnum,
} from '@axiom/validation';

import { ExperienceApi } from '../../api/experience-api';
import { CandidateExperienceApi } from '../../api/candidate-experience-api';
import { CandidateProfileIssuesApi } from '../../api/candidate-profile-issues-api';
import { WindowUtil } from '../../utils/window-util';

import { ExperienceEditContext } from './ExperienceEditContext';
import {
  ExperienceEditSectionKeys,
  ExperienceEditUtil,
} from './experience-edit-util';
import { ExperienceEditFormSkillsReviewModal } from './ExperienceEditFormSkillsReviewModal';
import {
  getDynamicFormSkillsSchema,
  dynamicSchemaType,
} from './experience-edit-skills-schema';

const { SKILLS } = ExperienceEditSectionKeys;

export type ExperienceEditFormSkillsBodyType = {
  candidate: Candidate;
  experience: Experience;
  taxonomy: Taxonomy;
};

export const ExperienceEditFormSkillsBody = ({
  candidate,
  experience,
  taxonomy,
}: ExperienceEditFormSkillsBodyType) => {
  const {
    dirtyFormState,
    setDirtyFormState,
    accordionState,
    setAccordionState,
  } = useContext(ExperienceEditContext);
  const { experienceTaxonomy = [], experiencesFocusAreaOrderPreferences = [] } =
    experience;

  const { isMobile } = useBreakpoint();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const focusAreaOrder: TaxonomyFocusAreaEnum[] =
    experiencesFocusAreaOrderPreferences?.[0]?.focusAreas ?? [];

  const generalSkillOptions = TaxonomyUtil.getGeneralSkillOptions(
    taxonomy.generalSkills
  );

  const isCandidateAlp = ProfessionConst.other.occupationTypes.includes(
    candidate.occupationType
  );

  const fieldConfiguration = useMemo(() => {
    return ExperienceEditUtil.getSkillsSectionConfiguration(
      focusAreaOrder,
      taxonomy,
      isCandidateAlp
    );
  }, [focusAreaOrder, taxonomy, isCandidateAlp]);

  const initialLegalSkillsByFocusArea = useMemo(
    () => ExperienceEditUtil.getLegalSkillsByFocusArea(experienceTaxonomy),
    [experienceTaxonomy]
  );

  const dynamicSchema = getDynamicFormSkillsSchema(focusAreaOrder, taxonomy);

  const [
    openFocusAreaSectionsWithDsSuggestions,
    setOpenFocusAreaSectionsWithDsSuggestions,
  ] = useState(
    experienceTaxonomy.reduce(
      (acc, curr) => {
        if (curr.dsSuggestion) {
          acc[curr.focusArea] = curr.focusArea === focusAreaOrder[0];
        }
        return acc;
      },
      {} as Record<TaxonomyFocusAreaEnum, boolean>
    )
  );

  const updateSectionHasBeenOpened = (focusArea: TaxonomyFocusAreaEnum) => {
    setOpenFocusAreaSectionsWithDsSuggestions(prev => ({
      ...prev,
      [focusArea]: true,
    }));
  };

  const shouldModalOpen = () => {
    return Object.keys(openFocusAreaSectionsWithDsSuggestions)
      .map(key => {
        return openFocusAreaSectionsWithDsSuggestions[key];
      })
      .includes(false);
  };

  return (
    <Form
      name="EXPERIENCE_EDIT_FORM_SKILLS"
      schema={dynamicSchema}
      initialValues={{
        generalSkills: experience.generalSkills ?? [],
        ...initialLegalSkillsByFocusArea,
      }}
      onSubmit={async (formData: dynamicSchemaType) => {
        if (shouldModalOpen() && !isModalOpen) {
          return setIsModalOpen(true);
        }

        const totalLegalSkills = focusAreaOrder.reduce(
          (acc, curr) => {
            acc[curr] = formData[curr] ?? initialLegalSkillsByFocusArea[curr];
            return acc;
          },
          {} as Record<TaxonomyFocusAreaEnum, TaxonomyLegalSkillEnum[]>
        );

        const legalSkills = Object.keys(totalLegalSkills).flatMap(
          key => totalLegalSkills[key]
        );

        await ExperienceApi.updateExperience(experience.id, {
          generalSkills:
            formData.generalSkills || (experience.generalSkills ?? []),
        });
        await ExperienceApi.updateCandidateExperienceTaxonomy(
          candidate.id,
          experience.id,
          [
            ...CandidateTaxonomyUtil.orderExperienceTaxonomyByFocusAreaOrderPreferences(
              experienceTaxonomy
                .filter(et => !et.legalSkills)
                .map(({ id, ...rest }) => ({ ...rest })),
              experiencesFocusAreaOrderPreferences
            ),
            ...CandidateTaxonomyUtil.convertLegalSkillsToExperienceTaxonomy(
              legalSkills,
              experience.id,
              taxonomy
            ),
          ]
        );
        await CandidateExperienceApi.refreshExperiences(candidate.id);
        await CandidateProfileIssuesApi.refreshCandidateProfileIssues(
          candidate.id
        );

        setAccordionState({
          ...ExperienceEditUtil.setAllToFalse(accordionState),
        });
        WindowUtil.scrollToTop();

        ToastUtil.add({
          name: 'SUCCESS_SKILLS',
          type: 'info',
          children: <>Your skills for {experience.client} have been saved!</>,
        });

        return setIsModalOpen(false);
      }}
    >
      {({ dirty, fireSubmit, values }) => {
        // https://stackoverflow.com/questions/61031464/setstate-called-in-render-prop-is-causing-a-react-warning
        setTimeout(() => {
          if (dirtyFormState[SKILLS] !== dirty) {
            setDirtyFormState((prevState: Record<string, boolean>) => ({
              ...prevState,
              [SKILLS]: dirty,
            }));
          }
        }, 0);

        return (
          <>
            <div data-test="EXPERIENCE_EDIT_FORM_SKILLS_BODY">
              <Well background="blue">
                <SectionHeader name="EXPERIENCE_EDIT_FORM_SKILLS_WELL_HEADER">
                  What skills did you use while at{' '}
                  {ExperienceEditUtil.getExperienceClientDateRangeLabel(
                    experience
                  )}
                  ?
                </SectionHeader>
                <Gutter bottom="8px" />
                <Paragraph>
                  Select the specific skills that you used during this
                  experience. We’ve selected some skills for you based on the
                  work description you provided. You can remove, add or edit
                  skills to your liking.
                </Paragraph>
              </Well>
              <Gutter bottom="16px" />
              <Accordions>
                {fieldConfiguration.map(({ focusArea, skillGroups }, index) => {
                  const selectedCount = values[focusArea.key]?.length ?? 0;
                  const hasSectionNotBeenOpened =
                    openFocusAreaSectionsWithDsSuggestions[focusArea.key] ===
                    false;

                  return (
                    <AccordionsItem
                      key={focusArea.key}
                      name={`FOCUS_AREA_GROUP_${index}_accordion`}
                    >
                      <AccordionsItemHeader
                        onToggle={isExpanded => {
                          if (isExpanded && hasSectionNotBeenOpened) {
                            updateSectionHasBeenOpened(focusArea.key);
                          }
                        }}
                      >
                        <Layout position="space-between middle">
                          <LayoutItem>
                            <CondensedHeader name="FOCUS_AREA_TITLE">
                              {focusArea.fullName}
                            </CondensedHeader>
                          </LayoutItem>
                          <LayoutItem position="center">
                            <Gutter right="16px">
                              <Badge
                                name="LEGAL_SKILLS_COUNT"
                                background={selectedCount > 0 ? 'gray' : 'red'}
                              >
                                {selectedCount}
                              </Badge>
                            </Gutter>
                          </LayoutItem>
                        </Layout>
                      </AccordionsItemHeader>
                      <AccordionsItemSection
                        name={`FOCUS_AREA_GROUP_${index}_SECTION`}
                      >
                        <Gutter bottom="24px" />
                        <FormGroup name={focusArea.key}>
                          <Layout direction="vertical" verticalGutter="16px">
                            {skillGroups.map(({ legalSkills, skillGroup }) => (
                              <Well
                                key={skillGroup.key}
                                name="SKILL_GROUP_LEGAL_SKILLS"
                              >
                                <CondensedHeader name="talentQuestion">
                                  {skillGroup.talentQuestion}
                                </CondensedHeader>
                                <Gutter bottom="8px" />
                                <Layout
                                  direction="horizontal"
                                  horizontalGutter="8px"
                                  verticalGutter="8px"
                                  stackableOn="mobile"
                                  wrap
                                >
                                  {legalSkills.map(legalSkill => (
                                    <Checkbox
                                      key={legalSkill.key}
                                      name={focusArea.key}
                                      displayValue={legalSkill.fullName}
                                      option={legalSkill.key}
                                      mode={isMobile ? 'checkbox' : 'pill'}
                                    />
                                  ))}
                                </Layout>
                              </Well>
                            ))}
                          </Layout>
                        </FormGroup>
                      </AccordionsItemSection>
                    </AccordionsItem>
                  );
                })}
                <AccordionsItem name="GENERAL_SKILLS_accordion">
                  <AccordionsItemHeader>
                    <Layout position="space-between middle">
                      <LayoutItem>
                        <CondensedHeader>
                          Optionally, do any of these other skills apply to this
                          experience?
                        </CondensedHeader>
                      </LayoutItem>
                      <LayoutItem position="center">
                        <Gutter right="16px">
                          <Badge background="gray" name="GENERAL_SKILLS_COUNT">
                            {values.generalSkills.length}
                          </Badge>
                        </Gutter>
                      </LayoutItem>
                    </Layout>
                  </AccordionsItemHeader>
                  <AccordionsItemSection>
                    <Gutter bottom="24px" />
                    <Well>
                      <FormGroup name="generalSkills">
                        <CondensedHeader>
                          General Skills (optional)
                        </CondensedHeader>
                        <Gutter bottom="8px" />
                        <Layout
                          horizontalGutter="8px"
                          verticalGutter="8px"
                          stackableOn="mobile"
                          wrap
                        >
                          {generalSkillOptions.map(({ label, value }) => (
                            <Checkbox
                              key={value}
                              name="generalSkills"
                              displayValue={label}
                              option={value}
                              mode={isMobile ? 'checkbox' : 'pill'}
                            />
                          ))}
                        </Layout>
                      </FormGroup>
                    </Well>
                  </AccordionsItemSection>
                </AccordionsItem>
              </Accordions>
              <Gutter bottom="16px" />
              <CondensedLarge>
                Do you have suggestions for additional skills to add? Send
                feedback to{' '}
                <a href="mailto:skills-feedback@axiomlaw.com?subject=Skills suggestions&body=Dear Axiom Product Team,%0D%0A%0D%0AI'd like to recommend that Axiom add the following skills:">
                  skills-feedback@axiomlaw.com
                </a>
              </CondensedLarge>
              <Gutter bottom="16px" />
              <FluidButtonLayout>
                <Button
                  pattern="secondary"
                  onClick={() => {
                    fireSubmit();
                  }}
                  name="EXPERIENCE_EDIT_FORM_SKILLS_SAVE"
                >
                  Save
                </Button>
              </FluidButtonLayout>
            </div>
            {isModalOpen && (
              <ExperienceEditFormSkillsReviewModal
                onClose={() => setIsModalOpen(false)}
                onSubmit={() => {
                  fireSubmit();
                }}
              />
            )}
          </>
        );
      }}
    </Form>
  );
};
