import React, { useMemo } from 'react';
import {
  Candidate,
  Experience,
  Taxonomy,
  TaxonomyFocusAreaEnum,
} from '@axiom/validation';
import {
  Form,
  Button,
  Gutter,
  Well,
  SectionHeader,
  Paragraph,
  Layout,
  Modal,
  ModalHeader,
  ModalSection,
  Checkbox,
  CondensedHeader,
  Grid,
  GridColumn,
  FormGroup,
  ModalFooter,
  Brief,
  CandidateTaxonomyUtil,
  TaxonomyUtil,
  useBreakpoint,
} from '@axiom/ui';
import { ProfessionConst } from '@axiom/const';

import { ExperienceEditUtil } from './experience-edit-util';
import { FocusAreasFormSchema } from './experience-edit-focus-areas-schema';

export type ExperienceEditFormFocusAreasSelectProps = {
  candidate: Candidate;
  experience: Experience;
  focusAreas: TaxonomyFocusAreaEnum[];
  name: string;
  onFocusAreasSelected: (focusAreas: TaxonomyFocusAreaEnum[]) => void;
  taxonomy: Taxonomy;
};

export const ExperienceEditFormFocusAreasSelect = ({
  candidate,
  experience,
  focusAreas,
  name,
  onFocusAreasSelected,
  taxonomy,
}: ExperienceEditFormFocusAreasSelectProps) => {
  const { isTablet } = useBreakpoint();
  const focusAreasByPracticeArea = useMemo(
    () =>
      TaxonomyUtil.getFocusAreaEnumsByPracticeAreaEnumForCandidate(
        taxonomy,
        candidate
      ),
    [taxonomy, candidate]
  );
  const focusAreaSuggestions = useMemo(() => {
    const isAlp = ProfessionConst.other.occupationTypes.includes(
      candidate.occupationType
    );
    return CandidateTaxonomyUtil.getFocusAreasFromExperienceTaxonomy(
      experience.experienceTaxonomy ?? []
    ).filter(
      ({ dsSuggestion, focusArea }) =>
        dsSuggestion && (!isAlp || taxonomy.focusAreas[focusArea].alpEligible)
    );
  }, [experience, candidate]);
  const onModalCancel = () => {
    onFocusAreasSelected(focusAreas);
  };

  return (
    <Form
      name={name}
      initialValues={{
        focusAreas,
      }}
      onSubmit={changedData =>
        Promise.resolve().then(() => {
          onFocusAreasSelected(changedData.focusAreas ?? focusAreas);
        })
      }
      schema={FocusAreasFormSchema}
    >
      {({ fireSubmit, values }) => {
        const focusAreaCheckboxesByPracticeArea = Object.keys(
          focusAreasByPracticeArea
        )
          .sort()
          .map(practiceArea => {
            return (
              <div
                key={practiceArea}
                data-test="EXPERIENCE_EDIT_FORM_SELECT_CHECKBOX_GROUP"
              >
                <CondensedHeader name="EXPERIENCE_EDIT_FORM_SELECT_CHECKBOX_GROUP_HEADER">
                  {taxonomy.practiceAreas[practiceArea].fullName}
                </CondensedHeader>
                <Gutter bottom="8px" />
                {focusAreasByPracticeArea[practiceArea]
                  .sort()
                  .map(focusArea => (
                    <div
                      key={focusArea}
                      data-test="EXPERIENCE_EDIT_FORM_SELECT_CHECKBOX_GROUP_CHECKBOX"
                    >
                      <Checkbox
                        displayValue={taxonomy.focusAreas[focusArea].fullName}
                        name="focusAreas"
                        option={focusArea}
                      />
                      <Gutter bottom="8px" />
                    </div>
                  ))}
              </div>
            );
          });
        const focusAreasCheckboxGrid = isTablet ? (
          <Grid name="EDIT_EXPERIENCE_FORM_CHECKBOX_GRID_TABLET">
            <GridColumn width={6}>
              <Layout direction="vertical" verticalGutter="16px">
                {focusAreaCheckboxesByPracticeArea.slice(0, 7)}
              </Layout>
            </GridColumn>
            <GridColumn width={6}>
              <Layout direction="vertical" verticalGutter="16px">
                {focusAreaCheckboxesByPracticeArea.slice(7)}
              </Layout>
            </GridColumn>
          </Grid>
        ) : (
          <Grid name="EDIT_EXPERIENCE_FORM_CHECKBOX_GRID_DEFAULT">
            <GridColumn largeScreenWidth={4} mobileWidth={12}>
              <Layout direction="vertical" verticalGutter="16px">
                {focusAreaCheckboxesByPracticeArea.slice(0, 6)}
              </Layout>
            </GridColumn>
            <GridColumn largeScreenWidth={4} mobileWidth={12}>
              <Layout direction="vertical" verticalGutter="16px">
                {focusAreaCheckboxesByPracticeArea.slice(6, 11)}
              </Layout>
            </GridColumn>
            <GridColumn largeScreenWidth={4} mobileWidth={12}>
              <Layout direction="vertical" verticalGutter="16px">
                {focusAreaCheckboxesByPracticeArea.slice(11)}
              </Layout>
            </GridColumn>
          </Grid>
        );
        return (
          <Modal
            size="large"
            name="EXPERIENCE_EDIT_FORM_SELECT_FOCUS_AREAS_MODAL"
          >
            <ModalHeader
              name="EXPERIENCE_EDIT_FORM_SELECT_FOCUS_AREAS_MODAL_HEADER"
              onClose={() => onModalCancel()}
            >
              Focus areas
            </ModalHeader>
            <ModalSection>
              <Well background="blue">
                <SectionHeader name="EXPERIENCE_EDIT_FORM_SELECT_FOCUS_AREAS_WELL_HEADER">
                  What focus areas did you work in during your engagement with{' '}
                  {ExperienceEditUtil.getExperienceClientDateRangeLabel(
                    experience
                  )}
                  ?
                </SectionHeader>
              </Well>
              <Gutter bottom="16px" />
              <FormGroup name="focusAreas">
                {focusAreaSuggestions.length > 0 && (
                  <Well name="EXPERIENCE_EDIT_FORM_FOCUS_AREA_SUGGESTION_WELL">
                    <Paragraph>
                      Here are suggested focus areas based on the work
                      description you provided.
                    </Paragraph>
                    <Gutter bottom="16px" />
                    <Layout horizontalGutter="8px" verticalGutter="8px" wrap>
                      {focusAreaSuggestions.map(({ focusArea }) => (
                        <Checkbox
                          key={focusArea}
                          displayValue={taxonomy.focusAreas[focusArea].fullName}
                          mode="pill"
                          name="focusAreas"
                          option={focusArea}
                        />
                      ))}
                    </Layout>
                  </Well>
                )}
                <Gutter bottom="16px" />
                {focusAreaSuggestions.length > 0 ? (
                  <Brief
                    name="EXPERIENCE_EDIT_FORM_SELECT_FOCUS_AREA_BRIEF"
                    briefMaxHeight="0"
                    label="Focus Areas"
                    expanded={values.focusAreas.some(
                      focusArea =>
                        !focusAreaSuggestions.some(
                          suggestion => suggestion.focusArea === focusArea
                        )
                    )}
                  >
                    {focusAreasCheckboxGrid}
                  </Brief>
                ) : (
                  focusAreasCheckboxGrid
                )}
              </FormGroup>
            </ModalSection>
            <ModalFooter>
              <Button
                name="EXPERIENCE_EDIT_FORM_SELECT_CANCEL"
                onClick={() => onModalCancel()}
                variation="outline"
              >
                Cancel
              </Button>
              <Button
                name="EXPERIENCE_EDIT_FORM_SELECT_SUBMIT"
                onClick={() => fireSubmit()}
              >
                Add focus areas
              </Button>
            </ModalFooter>
          </Modal>
        );
      }}
    </Form>
  );
};
