import React from 'react';
import { Experience, Taxonomy } from '@axiom/validation';

import { Accordions } from '../../layout/Accordions/Accordions';
import { AccordionsItem } from '../../layout/Accordions/AccordionsItem';
import { AccordionsItemHeader } from '../../layout/Accordions/AccordionsItemHeader';
import { AccordionsItemSection } from '../../layout/Accordions/AccordionsItemSection';
import { Badge } from '../../element/Badge/Badge';
import { CondensedHeader } from '../../content/CondensedHeader/CondensedHeader';
import { CondensedLarge } from '../../content/CondensedLarge/CondensedLarge';
import { Gutter } from '../../layout/Gutter/Gutter';
import { Layout } from '../../layout/Layout/Layout';
import { LayoutItem } from '../../layout/Layout/LayoutItem';
import { CandidateTaxonomyUtil } from '../../../utils/candidate-taxonomy-util';

export type CandidateExperienceTaxonomyDetailsProps = {
  experience: Experience;
  taxonomy: Taxonomy;
};

export const CandidateExperienceTaxonomyDetails = ({
  experience,
  taxonomy,
}: CandidateExperienceTaxonomyDetailsProps) => {
  const {
    experiencesFocusAreaOrderPreferences,
    generalSkills: experienceGeneralSkills,
    experienceTaxonomy,
  } = experience;
  const { focusAreas, legalSkills, skillGroups, generalSkills } = taxonomy;
  const hasGeneralSkills = (experienceGeneralSkills?.length ?? 0) > 0;

  const experienceTaxonomyByFocusArea =
    CandidateTaxonomyUtil.groupExperienceTaxonomyByFocusArea(
      experienceTaxonomy,
      experiencesFocusAreaOrderPreferences
    );

  const skillsCount = CandidateTaxonomyUtil.getSkillsCount(
    experienceTaxonomy,
    experienceGeneralSkills
  );

  return (
    <Accordions name="CANDIDATE_EXPERIENCE_TAXONOMY_DETAILS">
      <AccordionsItem>
        <AccordionsItemHeader name="CANDIDATE_EXPERIENCE_TAXONOMY_DETAILS_HEADER_accordion">
          <div
            data-test="pendo-analytics-experience-taxonomy-display"
            data-pendo-analytics-id="experience-taxonomy-display"
            data-pendo-analytics-experience-id={experience.id}
          >
            <Layout direction="horizontal" horizontalGutter="8px">
              <LayoutItem>
                <CondensedHeader>Skills used</CondensedHeader>
              </LayoutItem>
              <LayoutItem>
                <Badge background="light orange" name="SKILLS_COUNT">
                  {skillsCount}
                </Badge>
              </LayoutItem>
            </Layout>
          </div>
        </AccordionsItemHeader>
        <AccordionsItemSection>
          <Gutter bottom="24px" />
          <Layout direction="vertical" verticalGutter="16px">
            {experienceTaxonomyByFocusArea
              .filter(fa => (fa?.skillGroups.length ?? 0) > 0)
              .map(fa => (
                <LayoutItem key={fa?.focusArea} name="FOCUS_AREA_GROUP">
                  <CondensedHeader name="FOCUS_AREA">
                    {fa?.focusArea && focusAreas[fa.focusArea]?.fullName}
                  </CondensedHeader>
                  {fa?.skillGroups.map(sg => (
                    <CondensedLarge key={sg.skillGroup}>
                      <span data-test="SKILL_GROUP">
                        {skillGroups?.[sg.skillGroup]?.fullName}:{' '}
                      </span>
                      <span data-test="LEGAL_SKILLS">
                        {sg.legalSkills
                          .map(ls => legalSkills[ls]?.fullName)
                          .join('; ')}
                      </span>
                    </CondensedLarge>
                  ))}
                </LayoutItem>
              ))}
            {hasGeneralSkills && (
              <LayoutItem>
                <CondensedHeader>General Skills</CondensedHeader>
                <CondensedLarge name="GENERAL_SKILLS">
                  {experienceGeneralSkills
                    ?.sort()
                    .map(gs => generalSkills[gs]?.displayName)
                    .join('; ')}
                </CondensedLarge>
              </LayoutItem>
            )}
          </Layout>
        </AccordionsItemSection>
      </AccordionsItem>
    </Accordions>
  );
};
